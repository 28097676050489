<template>
    <div>
        <CModal
            class="backgroundModal"
            size="sm"
            color="dark"
            :closeOnBackdrop="false"
            :title='tituloModal'
            :show.sync="$store.state.planificacionestiba.modalContainerData"
        >   
            <div class="container-fluid">
                <CRow>
                    <CCol sm="4" style="margin:0px;padding:0px;padding-right: 10px;">
                        <CCard class="content-general">
                            <CCardTitle tag="h6">
                                <div class="title-card info-title">
                                    <CIcon name="cil-exclamation-circle"/>&nbsp;GENERAL INFO
                                </div>
                            </CCardTitle>
                            <CCardBody>
                                <CRow>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.ContainerCode,'ContainerCode')"
                                            :color="alertType($v.formContainerData.ContainerCode,'ContainerCode')"
                                            class="toatsAlert"
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.ContainerCode,'ContainerCode') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.ContainerCode,'ContainerCode')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <CInput
                                            label="CONTAINER CODE"
                                            
                                            addLabelClasses="required"
                                            :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                            size="sm"
                                            :maxlength="$v.formContainerData.ContainerCode.$params.maxLength.max"
                                            @keypress="maxLength($event,$v.formContainerData.ContainerCode.$params.maxLength.max)"
                                            v-model="$v.formContainerData.ContainerCode.$model"
                                            :addInputClasses="alertasInputs($v.formContainerData.ContainerCode,'ContainerCode')"
                                            @focus="$v.formContainerData.ContainerCode.$touch()"
                                            @blur="$v.formContainerData.ContainerCode.$touch()"
                                        >
                                            <template #invalid-feedback v-if="$v.formContainerData.ContainerCode.$error">
                                                <div class='text-danger' v-if="!$v.formContainerData.ContainerCode.required">{{$t('label.required')}}</div>
                                                <div class='text-danger' v-if="!$v.formContainerData.ContainerCode.SoloContenedores">{{$t('label.container_data.not_siglas')}}</div>
                                                <div class='text-danger' v-if="!$v.formContainerData.ContainerCode.onlyAlphanumeric">{{$t('label.onlyAlphanumeric')}}</div>
                                                <div class='text-danger' v-if="!$v.formContainerData.ContainerCode.checkDigit">{{$t('validation.checkDigit')}}</div>
                                                <div class='text-danger' v-if="!$v.formContainerData.ContainerCode.minLength">{{$t('label.min')+$v.formContainerData.ContainerCode.$params.minLength.min+$t('label.chars')}} </div>
                                                <div class='text-danger' v-if="!$v.formContainerData.ContainerCode.maxLength">{{$t('label.max')+$v.formContainerData.ContainerCode.$params.maxLength.max+$t('label.chars')}} </div>
                                            </template>
                                        </CInput>
                                        
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.Size,'Size')"
                                            :color="alertType($v.formContainerData.Size,'Size')"
                                            class="toatsAlert"
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.Size,'Size') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.Size,'Size')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <CInput
                                            label="SIZE FT"
                                            
                                            disabled
                                            v-model="$v.formContainerData.Size.$model"
                                            :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                            size="sm"
                                            :addInputClasses="alertasInputs($v.formContainerData.Size,'Size')"
                                            @focus="$v.formContainerData.Size.$touch()"
                                        >
                                            <template #invalid-feedback v-if="$v.formContainerData.Size.$error">
                                                <div class='text-danger' v-if="!$v.formContainerData.Size.required">{{$t('label.required')}}</div>
                                                <div class='text-danger' v-if="!$v.formContainerData.Size.minLength">{{$t('label.min')+$v.formContainerData.Size.$params.minLength.min+$t('label.chars')}} </div>
                                                <div class='text-danger' v-if="!$v.formContainerData.Size.maxLength">{{$t('label.max')+$v.formContainerData.Size.$params.maxLength.max+$t('label.chars')}} </div>
                                            </template>
                                        </CInput>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.BayCode,'BayCode')"
                                            :color="alertType($v.formContainerData.BayCode,'BayCode')"
                                            class="toatsAlert"
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.BayCode,'BayCode') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.BayCode,'BayCode')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <div class="form-group row" style="margin-bottom:0px">
                                            <label for="staticEmail" class="col-sm-5 required col-form-label col-form-label-sm">BAY</label>
                                            <div class="col-sm-7">
                                                <div class="row">
                                                    <div class="col" style="padding-left:8px;padding-right: 2px">
                                                        <CInput
                                                            size="sm"
                                                            disabled
                                                            :value="formContainerData.BayCode"
                                                            :addInputClasses="alertasInputs($v.formContainerData.BayCode,'BayCode')"
                                                            @focus="$v.formContainerData.BayCode.$touch()"
                                                        >
                                                            <template #invalid-feedback v-if="$v.formContainerData.BayCode.$error">
                                                                <div class='text-danger' v-if="obligationBaySlot && !$v.formContainerData.BayCode.required">{{$t('label.required')}}</div>
                                                                <div class='text-danger' v-if="obligationBaySlot && !$v.formContainerData.BayCode.numeric">{{$t('label.onlyNumber')}}</div>
                                                                <div class='text-danger' v-if="obligationBaySlot && !$v.formContainerData.BayCode.minLength">{{$t('label.min')+$v.formContainerData.BayCode.$params.minLength.min+$t('label.chars')}} </div>
                                                                <div class='text-danger' v-if="obligationBaySlot && !$v.formContainerData.BayCode.maxLength">{{$t('label.max')+$v.formContainerData.BayCode.$params.maxLength.max+$t('label.chars')}} </div>
                                                            </template>
                                                        </CInput>
                                                    </div>
                                                    <div class="col" style="padding-left:0px">
                                                        <CSelect
                                                            size="sm"
                                                            v-model="formContainerData.VesselBayId"
                                                            :addInputClasses="{ 'is-invalid': $v.formContainerData.BayCode.$error || $v.formContainerData.VesselBayId.$error}"
                                                            :value.sync="formContainerData.VesselBayId"
                                                            :options="optionListBayAll"
                                                            @change="checkBay"
                                                            @focus="$v.formContainerData.VesselBayId.$touch()"
                                                        >
                                                        </CSelect>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.BaySlot,'BaySlot')"
                                            :color="alertType($v.formContainerData.BaySlot,'BaySlot')"
                                            class="toatsAlert"
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.BaySlot,'BaySlot') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.BaySlot,'BaySlot')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <div class="form-group row" style="margin-bottom:0px">
                                            <label for="staticEmail" class="col-sm-5 required col-form-label col-form-label-sm">SLOT</label>
                                            <div class="col-sm-7">
                                                <div class="row">
                                                    <div class="col" style="padding-left:8px;padding-right: 2px">
                                                        <CInput
                                                            size="sm"
                                                            disabled
                                                            :value="formContainerData.BaySlot"
                                                            :addInputClasses="alertasInputs($v.formContainerData.BaySlot,'BaySlot')"
                                                            @focus="$v.formContainerData.BaySlot.$touch()"
                                                        >
                                                            <template #invalid-feedback v-if="$v.formContainerData.BaySlot.$error">
                                                                <div class='text-danger' v-if="obligationBaySlot && !$v.formContainerData.BaySlot.required">{{$t('label.required')}}</div>
                                                                <div class='text-danger' v-if="obligationBaySlot && !$v.formContainerData.BaySlot.numeric">{{$t('label.onlyNumber')}}</div>
                                                                <div class='text-danger' v-if="obligationBaySlot && !$v.formContainerData.BaySlot.minLength">{{$t('label.min')+$v.formContainerData.BaySlot.$params.minLength.min+$t('label.chars')}} </div>
                                                                <div class='text-danger' v-if="obligationBaySlot && !$v.formContainerData.BaySlot.maxLength">{{$t('label.max')+$v.formContainerData.BaySlot.$params.maxLength.max+$t('label.chars')}} </div>
                                                            </template>
                                                        </CInput>
                                                    </div>
                                                    <div class="col" style="padding-left:0px">
                                                        <CSelect
                                                            size="sm"
                                                            :addInputClasses="{ 'is-invalid': $v.formContainerData.BaySlot.$error || $v.formContainerData.VesselBayPosId.$error}"
                                                            :value.sync="$v.formContainerData.VesselBayPosId.$model"
                                                            :options="optionListSlotAll"
                                                            @change="checkSlot"
                                                            @focus="$v.formContainerData.VesselBayPosId.$touch()"
                                                        >
                                                        </CSelect>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.Class,'Class')"
                                            :color="alertType($v.formContainerData.Class,'Class')"
                                            class="toatsAlert"
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.Class,'Class') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.Class,'Class')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <div class="form-group row" style="margin-bottom:0px">
                                            <label for="staticSize" class="col-sm-5 col-form-label col-form-label-sm">CLASS</label>
                                            <div class="col-sm-7">
                                                <div class="row">
                                                    <div class="col" style="padding-left:8px;padding-right: 2px">
                                                        <CInput
                                                            size="sm"
                                                            disabled
                                                            :value="formContainerData.Class"
                                                            :addInputClasses="alertasInputs($v.formContainerData.Class,'Class')"
                                                            @focus="$v.formContainerData.Class.$touch()"
                                                        >
                                                            <template #invalid-feedback v-if="$v.formContainerData.Class.$error">
                                                                <div class='text-danger' v-if="!$v.formContainerData.Class.minLength">{{$t('label.min')+$v.formContainerData.Class.$params.minLength.min+$t('label.chars')}} </div>
                                                                <div class='text-danger' v-if="!$v.formContainerData.Class.maxLength">{{$t('label.max')+$v.formContainerData.Class.$params.maxLength.max+$t('label.chars')}} </div>
                                                            </template>
                                                        </CInput>
                                                    </div>
                                                    <div class="col" style="padding-left:0px">
                                                        <CSelect
                                                            size="sm"
                                                            v-model="formContainerData.TpCargoClassId"
                                                            :addInputClasses="{ 'is-invalid': $v.formContainerData.Class.$error || $v.formContainerData.TpCargoClassId.$error}"
                                                            :value.sync="formContainerData.TpCargoClassId"
                                                            :options="optionsListClass"
                                                            @change="checkClass"
                                                            @focus="$v.formContainerData.TpCargoClassId.$touch()"
                                                        >
                                                        </CSelect>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.Status,'Status')"
                                            :color="alertType($v.formContainerData.Status,'Status')"
                                            class="toatsAlert"
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.Status,'Status') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.Status,'Status')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <div class="form-group row" style="margin-bottom:0px">
                                            <label for="staticStatus" class="col-sm-5 required col-form-label col-form-label-sm">STATUS</label>
                                            <div class="col-sm-7">
                                                <div class="row">
                                                    <div class="col" style="padding-left:8px;padding-right: 2px">
                                                        <CInput
                                                            size="sm"
                                                            disabled
                                                            :value="formContainerData.Status"
                                                            :addInputClasses="alertasInputs($v.formContainerData.Status,'Status')"
                                                            @focus="$v.formContainerData.Status.$touch()"
                                                        >
                                                            <template #invalid-feedback v-if="$v.formContainerData.Status.$error">
                                                                <div class='text-danger' v-if="!$v.formContainerData.Status.required">{{$t('label.required')}}</div>
                                                                <div class='text-danger' v-if="!$v.formContainerData.Status.minLength">{{$t('label.min')+$v.formContainerData.Status.$params.minLength.min+$t('label.chars')}} </div>
                                                                <div class='text-danger' v-if="!$v.formContainerData.Status.maxLength">{{$t('label.max')+$v.formContainerData.Status.$params.maxLength.max+$t('label.chars')}} </div>
                                                            </template>
                                                        </CInput>
                                                    </div>
                                                    <div class="col" style="padding-left:0px">
                                                        <CSelect
                                                            size="sm"
                                                            v-model="formContainerData.TpCargoStatusId"
                                                            :addInputClasses="{ 'is-invalid': $v.formContainerData.Status.$error || $v.formContainerData.TpCargoStatusId.$error}"
                                                            :value.sync="formContainerData.TpCargoStatusId"
                                                            :options="optionsListCargoStatus"
                                                            @change="checkCargoStatus($event)"
                                                            @focus="$v.formContainerData.TpCargoStatusId.$touch()"
                                                        >
                                                        </CSelect>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.LoadPort,'LoadPort')"
                                            :color="alertType($v.formContainerData.LoadPort,'LoadPort')"
                                            class="toatsAlert"
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.LoadPort,'LoadPort') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.LoadPort,'LoadPort')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <div class="form-group row" style="margin-bottom:0px">
                                            <label for="staticLoad" class="col-sm-5 required col-form-label col-form-label-sm">LOAD PORT</label>
                                            <div class="col-sm-7">
                                                <div class="row">
                                                    <div class="col-6" style="padding-left:8px;padding-right: 2px">
                                                        <CInput
                                                            size="sm"
                                                            disabled
                                                            :value="formContainerData.LoadPort"
                                                            :addInputClasses="alertasInputs($v.formContainerData.LoadPort,'LoadPort')"
                                                            @focus="$v.formContainerData.LoadPort.$touch()"
                                                        >
                                                            
                                                        </CInput>
                                                    </div>
                                                    <div class="col-6" style="padding-left:0px">
                                                        <CSelect
                                                            size="sm"
                                                            v-model="formContainerData.LoadPortId"
                                                            :addInputClasses="{ 'is-invalid': $v.formContainerData.LoadPort.$error || $v.formContainerData.LoadPortId.$error}"
                                                            :value.sync="formContainerData.LoadPortId"
                                                            :options="optionsListPortAll"
                                                            @change="checkLoadPort"
                                                            @focus="$v.formContainerData.LoadPortId.$touch();$v.formContainerData.LoadPort.$touch();"
                                                        >
                                                        </CSelect>
                                                    </div>
                                                    <div class="col-12" v-if="$v.formContainerData.LoadPort.$error">
                                                        <div class='text-danger' v-if="!$v.formContainerData.LoadPort.required">{{$t('label.required')}}</div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.LoadPort.minLength">{{$t('label.min')+$v.formContainerData.LoadPort.$params.minLength.min+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.LoadPort.maxLength">{{$t('label.max')+$v.formContainerData.LoadPort.$params.maxLength.max+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.LoadPort.notsameAsport">{{$t('label.container_data.notsameAsDischarge')}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.DischargePort,'DischargePort')"
                                            :color="alertType($v.formContainerData.DischargePort,'DischargePort')"
                                            class="toatsAlert"
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.DischargePort,'DischargePort') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.DischargePort,'DischargePort')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <div class="form-group row" style="margin-bottom:0px">
                                            <label for="staticLoad" class="col-sm-5 required col-form-label col-form-label-sm">DISCHARGE PORT</label>
                                            <div class="col-sm-7">
                                                <div class="row">
                                                    <div class="col-6" style="padding-left:8px;padding-right: 2px">
                                                        <CInput
                                                            size="sm"
                                                            disabled
                                                            :value="formContainerData.DischargePort"
                                                            :addInputClasses="alertasInputs($v.formContainerData.DischargePort,'DischargePort')"
                                                            @focus="$v.formContainerData.DischargePort.$touch()"
                                                        >
                                                        </CInput>
                                                    </div>
                                                    <div class="col-6" style="padding-left:0px">
                                                        <CSelect
                                                            size="sm"
                                                            v-model="formContainerData.DischargePortId"
                                                            :addInputClasses="{ 'is-invalid': $v.formContainerData.DischargePort.$error || $v.formContainerData.DischargePortId.$error}"
                                                            :value.sync="formContainerData.DischargePortId"
                                                            :options="optionsListPortAll"
                                                            @change="checkDischargePort"
                                                            @focus="$v.formContainerData.DischargePortId.$touch();$v.formContainerData.DischargePort.$touch();"
                                                        >
                                                        </CSelect>
                                                    </div>
                                                    <div class="col-12" v-if="$v.formContainerData.DischargePort.$error">
                                                        <div class='text-danger' v-if="!$v.formContainerData.DischargePort.required">{{$t('label.required')}}</div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.DischargePort.minLength">{{$t('label.min')+$v.formContainerData.DischargePort.$params.minLength.min+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.DischargePort.maxLength">{{$t('label.max')+$v.formContainerData.DischargePort.$params.maxLength.max+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.DischargePort.notsameAsport">{{$t('label.container_data.notsameAsLoading')}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.DeliveryPort,'DeliveryPort')"
                                            :color="alertType($v.formContainerData.DeliveryPort,'DeliveryPort')"
                                            class="toatsAlert"
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.DeliveryPort,'DeliveryPort') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.DeliveryPort,'DeliveryPort')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <div class="form-group row" style="margin-bottom:0px">
                                            <label for="staticLoad" class="col-sm-5 col-form-label col-form-label-sm">DELIVERY PORT</label>
                                            <div class="col-sm-7">
                                                <div class="row">
                                                    <div class="col" style="padding-left:8px;padding-right: 2px">
                                                        <CInput
                                                            size="sm"
                                                            disabled
                                                            :value="formContainerData.DeliveryPort"
                                                            :addInputClasses="alertasInputs($v.formContainerData.DeliveryPort,'DeliveryPort')"
                                                            @focus="$v.formContainerData.DeliveryPort.$touch()"
                                                        >
                                                            <template #invalid-feedback v-if="$v.formContainerData.DeliveryPort.$error">
                                                                <div class='text-danger' v-if="!$v.formContainerData.DeliveryPort.minLength">{{$t('label.min')+$v.formContainerData.DeliveryPort.$params.minLength.min+$t('label.chars')}} </div>
                                                                <div class='text-danger' v-if="!$v.formContainerData.DeliveryPort.maxLength">{{$t('label.max')+$v.formContainerData.DeliveryPort.$params.maxLength.max+$t('label.chars')}} </div>
                                                            </template>
                                                        </CInput>
                                                    </div>
                                                    <div class="col" style="padding-left:0px">
                                                        <CSelect
                                                            size="sm"
                                                            v-model="formContainerData.DeliveryPortId"
                                                            :addInputClasses="{ 'is-invalid': $v.formContainerData.DeliveryPort.$error || $v.formContainerData.DeliveryPortId.$error}"
                                                            :value.sync="formContainerData.DeliveryPortId"
                                                            :options="optionsListDeliveryAll"
                                                            @change="checkDeliveryPort"
                                                            @focus="$v.formContainerData.DeliveryPortId.$touch()"
                                                        >
                                                        </CSelect>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.OptionalPort,'OptionalPort')"
                                            :color="alertType($v.formContainerData.OptionalPort,'OptionalPort')"
                                            class="toatsAlert"
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.OptionalPort,'OptionalPort') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.OptionalPort,'OptionalPort')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <div class="form-group row" style="margin-bottom:0px">
                                            <label for="staticLoad" class="col-sm-5 col-form-label col-form-label-sm">OPTIONAL PORT</label>
                                            <div class="col-sm-7">
                                                <div class="row">
                                                    <div class="col" style="padding-left:8px;padding-right: 2px">
                                                        <CInput
                                                            size="sm"
                                                            disabled
                                                            :value="formContainerData.OptionalPort"
                                                            :addInputClasses="alertasInputs($v.formContainerData.OptionalPort,'OptionalPort')"
                                                            @focus="$v.formContainerData.OptionalPort.$touch()"
                                                        >
                                                            <template #invalid-feedback v-if="$v.formContainerData.OptionalPort.$error">
                                                                <div class='text-danger' v-if="!$v.formContainerData.OptionalPort.minLength">{{$t('label.min')+$v.formContainerData.OptionalPort.$params.minLength.min+$t('label.chars')}} </div>
                                                                <div class='text-danger' v-if="!$v.formContainerData.OptionalPort.maxLength">{{$t('label.max')+$v.formContainerData.OptionalPort.$params.maxLength.max+$t('label.chars')}} </div>
                                                            </template>
                                                        </CInput>
                                                    </div>
                                                    <div class="col" style="padding-left:0px">
                                                        <CSelect
                                                            size="sm"
                                                            v-model="formContainerData.OptionalPortId"
                                                            :addInputClasses="{ 'is-invalid': $v.formContainerData.DeliveryPort.$error || $v.formContainerData.OptionalPortId.$error}"
                                                            :value.sync="formContainerData.OptionalPortId"
                                                            :options="optionsListDeliveryAll"
                                                            @change="checkOptionalPort"
                                                            @focus="$v.formContainerData.OptionalPortId.$touch()"
                                                        >
                                                        </CSelect>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.OriginalPortLoading,'OriginalPortLoading')"
                                            :color="alertType($v.formContainerData.OriginalPortLoading,'OriginalPortLoading')"
                                            class="toatsAlert"
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.OriginalPortLoading,'OriginalPortLoading') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.OriginalPortLoading,'OriginalPortLoading')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <div class="form-group row" style="margin-bottom:0px">
                                            <label for="staticLoad" class="col-sm-5 col-form-label col-form-label-sm">ORIGINAL PORT OF LOADING</label>
                                            <div class="col-sm-7">
                                                <div class="row">
                                                    <div class="col" style="padding-left:8px;padding-right: 2px">
                                                        <CInput
                                                            size="sm"
                                                            disabled
                                                            :value="formContainerData.OriginalPortLoading"
                                                            :addInputClasses="alertasInputs($v.formContainerData.OriginalPortLoading,'OriginalPortLoading')"
                                                            @focus="$v.formContainerData.OriginalPortLoading.$touch()"
                                                        >
                                                            <template #invalid-feedback v-if="$v.formContainerData.OriginalPortLoading.$error">
                                                                <div class='text-danger' v-if="!$v.formContainerData.OriginalPortLoading.minLength">{{$t('label.min')+$v.formContainerData.OriginalPortLoading.$params.minLength.min+$t('label.chars')}} </div>
                                                                <div class='text-danger' v-if="!$v.formContainerData.OriginalPortLoading.maxLength">{{$t('label.max')+$v.formContainerData.OriginalPortLoading.$params.maxLength.max+$t('label.chars')}} </div>
                                                            </template>
                                                        </CInput>
                                                    </div>
                                                    <div class="col" style="padding-left:0px">
                                                        <CSelect
                                                            size="sm"
                                                            v-model="formContainerData.OriginalPortLoadingId"
                                                            :addInputClasses="{ 'is-invalid': $v.formContainerData.OriginalPortLoading.$error || $v.formContainerData.OriginalPortLoadingId.$error}"
                                                            :value.sync="formContainerData.OriginalPortLoadingId"
                                                            :options="optionsListDeliveryAll"
                                                            @change="checkOriginalPortLoading"
                                                            @focus="$v.formContainerData.OriginalPortLoadingId.$touch()"
                                                        >
                                                        </CSelect>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.TranshipmentPort,'TranshipmentPort')"
                                            :color="alertType($v.formContainerData.TranshipmentPort,'TranshipmentPort')"
                                            class="toatsAlert"
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.TranshipmentPort,'TranshipmentPort') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.TranshipmentPort,'TranshipmentPort')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <div class="form-group row" style="margin-bottom:0px">
                                            <label for="staticLoad" class="col-sm-5 col-form-label col-form-label-sm">TRANSSHIPMENT PORT</label>
                                            <div class="col-sm-7">
                                                <div class="row">
                                                    <div class="col" style="padding-left:8px;padding-right: 2px">
                                                        <CInput
                                                            size="sm"
                                                            disabled
                                                            :value="formContainerData.TranshipmentPort"
                                                            :addInputClasses="alertasInputs($v.formContainerData.TranshipmentPort,'TranshipmentPort')"
                                                            @focus="$v.formContainerData.TranshipmentPort.$touch()"
                                                        >
                                                            <template #invalid-feedback v-if="$v.formContainerData.TranshipmentPort.$error">
                                                                <div class='text-danger' v-if="!$v.formContainerData.TranshipmentPort.minLength">{{$t('label.min')+$v.formContainerData.TranshipmentPort.$params.minLength.min+$t('label.chars')}} </div>
                                                                <div class='text-danger' v-if="!$v.formContainerData.TranshipmentPort.maxValue">{{$t('label.max')+$v.formContainerData.TranshipmentPort.$params.maxValue.max}} </div>
                                                            </template>
                                                        </CInput>
                                                    </div>
                                                    <div class="col" style="padding-left:0px">
                                                        <CSelect
                                                            size="sm"
                                                            v-model="formContainerData.TranshipmentPortId"
                                                            :addInputClasses="{ 'is-invalid': $v.formContainerData.TranshipmentPort.$error || $v.formContainerData.TranshipmentPortId.$error}"
                                                            :value.sync="formContainerData.TranshipmentPortId"
                                                            :options="optionsListDeliveryAll"
                                                            @change="checkTranshipmentPort"
                                                            @focus="$v.formContainerData.TranshipmentPortId.$touch()"
                                                        >
                                                        </CSelect>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.ShippingLineCode,'ShippingLineCode')"
                                            :color="alertType($v.formContainerData.ShippingLineCode,'ShippingLineCode')"
                                            class="toatsAlert"
                                            
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.ShippingLineCode,'ShippingLineCode') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.ShippingLineCode,'ShippingLineCode')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <div class="form-group row" style="margin-bottom:0px">
                                            <label for="staticCarrier" class="col-sm-5 required col-form-label col-form-label-sm">CARRIER CODE</label>
                                            <div class="col-sm-7">
                                                <div class="row">
                                                    <div class="col" style="padding-left:8px;padding-right: 2px">
                                                        <CInput
                                                            size="sm"
                                                            disabled
                                                            :value="formContainerData.ShippingLineCode"
                                                            :addInputClasses="alertasInputs($v.formContainerData.ShippingLineCode,'ShippingLineCode')"
                                                            @focus="$v.formContainerData.ShippingLineCode.$touch()"
                                                        >
                                                            <template #invalid-feedback v-if="$v.formContainerData.ShippingLineCode.$error">
                                                                <div class='text-danger' v-if="!$v.formContainerData.ShippingLineCode.required">{{$t('label.required')}}</div>
                                                                <div class='text-danger' v-if="!$v.formContainerData.ShippingLineCode.minLength">{{$t('label.min')+$v.formContainerData.ShippingLineCode.$params.minLength.min+$t('label.chars')}} </div>
                                                                <div class='text-danger' v-if="!$v.formContainerData.ShippingLineCode.maxLength">{{$t('label.max')+$v.formContainerData.ShippingLineCode.$params.maxLength.max+$t('label.chars')}} </div>
                                                            </template>
                                                        </CInput>
                                                    </div>
                                                    <div class="col" style="padding-left:0px">
                                                        <CSelect
                                                            size="sm"
                                                            v-model="formContainerData.ShippingLineId"
                                                            :value.sync="formContainerData.ShippingLineId"
                                                            :addInputClasses="{ 'is-invalid': $v.formContainerData.ShippingLineCode.$error || $v.formContainerData.ShippingLineId.$error}"
                                                            :options="optionsListCarrier"
                                                            @change="checkCarrier"
                                                            @focus="$v.formContainerData.ShippingLineId.$touch()"
                                                        >
                                                        </CSelect>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.ShippingLineName,'ShippingLineName')"
                                            :color="alertType($v.formContainerData.ShippingLineName,'ShippingLineName')"
                                            class="toatsAlert"
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.ShippingLineName,'ShippingLineName') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.ShippingLineName,'ShippingLineName')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <CInput
                                            size="sm"
                                            disabled
                                            label="CARRIER"
                                            :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                            v-model="$v.formContainerData.ShippingLineName.$model"
                                            :addInputClasses="alertasInputs($v.formContainerData.ShippingLineName,'ShippingLineName')"
                                            @focus="$v.formContainerData.ShippingLineName.$touch()"
                                        >
                                            <template #invalid-feedback v-if="$v.formContainerData.ShippingLineName.$error">
                                                <div class='text-danger' v-if="!$v.formContainerData.ShippingLineName.minLength">{{$t('label.min')+$v.formContainerData.ShippingLineName.$params.minLength.min+$t('label.chars')}} </div>
                                                <div class='text-danger' v-if="!$v.formContainerData.ShippingLineName.maxValue">{{$t('label.max')+$v.formContainerData.ShippingLineName.$params.maxValue.max}} </div>
                                            </template>
                                        </CInput>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.HeadingId,'HeadingId')"
                                            :color="alertType($v.formContainerData.HeadingId,'HeadingId')"
                                            class="toatsAlert"
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.HeadingId,'HeadingId') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.HeadingId,'HeadingId')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <CSelect
                                            size="sm"
                                            label="HEADING"
                                            :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                            v-model="formContainerData.HeadingId"
                                            :value.sync="formContainerData.HeadingId"
                                            :addInputClasses="alertasInputs($v.formContainerData.HeadingId,'HeadingId')"
                                            @change="headingCheck"
                                            :options="optionsListHeadingAll"
                                            @focus="$v.formContainerData.HeadingId.$touch()"
                                        >
                                        </CSelect>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.CommodityId,'CommodityId')"
                                            :color="alertType($v.formContainerData.CommodityId,'CommodityId')"
                                            class="toatsAlert"
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.CommodityId,'CommodityId') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.CommodityId,'CommodityId')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <CSelect
                                            size="sm"
                                            label="COMMODITY"
                                            :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                            v-model="formContainerData.CommodityId"
                                            :addInputClasses="alertasInputs($v.formContainerData.CommodityId,'CommodityId')"
                                            :value.sync="formContainerData.CommodityId"
                                            :options="optionsListCommodityAll"
                                            @focus="$v.formContainerData.CommodityId.$touch()"
                                        >
                                        </CSelect>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.CommodityCode,'CommodityCode')"
                                            :color="alertType($v.formContainerData.CommodityCode,'CommodityCode')"
                                            class="toatsAlert"
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.CommodityCode,'CommodityCode') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.CommodityCode,'CommodityCode')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <CInput
                                            size="sm"
                                            
                                            label="COMMODITY CODE"
                                            :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                            v-model="$v.formContainerData.CommodityCode.$model"
                                            :addInputClasses="alertasInputs($v.formContainerData.CommodityCode,'CommodityCode')"
                                            @focus="$v.formContainerData.CommodityCode.$touch()"
                                        >
                                            <template #invalid-feedback v-if="$v.formContainerData.CommodityCode.$error">
                                                <div class='text-danger' v-if="!$v.formContainerData.CommodityCode.maxLength">{{$t('label.max')+$v.formContainerData.CommodityCode.$params.maxLength.max+$t('label.chars')}} </div>
                                            </template>
                                        </CInput>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.CommodityIdNumber,'CommodityIdNumber')"
                                            :color="alertType($v.formContainerData.CommodityIdNumber,'CommodityIdNumber')"
                                            class="toatsAlert"
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.CommodityIdNumber,'CommodityIdNumber') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.CommodityIdNumber,'CommodityIdNumber')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <CInput
                                            size="sm"
                                            
                                            label="COMMODITY ID NUMBER"
                                            :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                            v-model="$v.formContainerData.CommodityIdNumber.$model"
                                            :addInputClasses="alertasInputs($v.formContainerData.CommodityIdNumber,'CommodityIdNumber')"
                                            @focus="$v.formContainerData.CommodityIdNumber.$touch()"
                                        >
                                            <template #invalid-feedback v-if="$v.formContainerData.CommodityIdNumber.$error">
                                                <div class='text-danger' v-if="!$v.formContainerData.CommodityIdNumber.maxLength">{{$t('label.max')+$v.formContainerData.CommodityIdNumber.$params.maxLength.max+$t('label.chars')}} </div>
                                            </template>
                                        </CInput>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.HandlingRemark,'HandlingRemark')"
                                            :color="alertType($v.formContainerData.HandlingRemark,'HandlingRemark')"
                                            class="toatsAlert"
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.HandlingRemark,'HandlingRemark') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.HandlingRemark,'HandlingRemark')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <CInput
                                            size="sm"
                                            
                                            label="HANDLING REMARK"
                                            :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                            v-model="$v.formContainerData.HandlingRemark.$model"
                                            :addInputClasses="alertasInputs($v.formContainerData.HandlingRemark,'HandlingRemark')"
                                            @focus="$v.formContainerData.HandlingRemark.$touch()"
                                        >
                                            <template #invalid-feedback v-if="$v.formContainerData.HandlingRemark.$error">
                                                <div class='text-danger' v-if="!$v.formContainerData.HandlingRemark.maxLength">{{$t('label.max')+$v.formContainerData.HandlingRemark.$params.maxLength.max+$t('label.chars')}} </div>
                                            </template>
                                        </CInput>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.ContainerRemark,'ContainerRemark')"
                                            :color="alertType($v.formContainerData.ContainerRemark,'ContainerRemark')"
                                            class="toatsAlert"
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.ContainerRemark,'ContainerRemark') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.ContainerRemark,'ContainerRemark')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <CInput
                                            size="sm"
                                            
                                            label="CONTAINER REMARK"
                                            :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                            v-model="$v.formContainerData.ContainerRemark.$model"
                                            :addInputClasses="alertasInputs($v.formContainerData.ContainerRemark,'ContainerRemark')"
                                            @focus="$v.formContainerData.ContainerRemark.$touch()"
                                        >
                                            <template #invalid-feedback v-if="$v.formContainerData.ContainerRemark.$error">
                                                <div class='text-danger' v-if="!$v.formContainerData.ContainerRemark.maxLength">{{$t('label.max')+$v.formContainerData.ContainerRemark.$params.maxLength.max+$t('label.chars')}} </div>
                                            </template>
                                        </CInput>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.SpecialInstruction,'SpecialInstruction')"
                                            :color="alertType($v.formContainerData.SpecialInstruction,'SpecialInstruction')"
                                            class="toatsAlert"
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.SpecialInstruction,'SpecialInstruction') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.SpecialInstruction,'SpecialInstruction')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <CInput
                                            size="sm"
                                            
                                            label="SPECIAL INSTRUCTION"
                                            :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                            v-model="$v.formContainerData.SpecialInstruction.$model"
                                            :addInputClasses="alertasInputs($v.formContainerData.SpecialInstruction,'SpecialInstruction')"
                                            @focus="$v.formContainerData.SpecialInstruction.$touch()"
                                        >
                                            <template #invalid-feedback v-if="$v.formContainerData.SpecialInstruction.$error">
                                                <div class='text-danger' v-if="!$v.formContainerData.SpecialInstruction.maxLength">{{$t('label.max')+$v.formContainerData.SpecialInstruction.$params.maxLength.max+$t('label.chars')}} </div>
                                            </template>
                                        </CInput>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.GeneralInfo,'GeneralInfo')"
                                            :color="alertType($v.formContainerData.GeneralInfo,'GeneralInfo')"
                                            class="toatsAlert"
                                            
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.GeneralInfo,'GeneralInfo') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.GeneralInfo,'GeneralInfo')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <CInput
                                            size="sm"
                                            
                                            label="GENERAL INFO"
                                            :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                            v-model="$v.formContainerData.GeneralInfo.$model"
                                            :addInputClasses="alertasInputs($v.formContainerData.GeneralInfo,'GeneralInfo')"
                                            @focus="$v.formContainerData.GeneralInfo.$touch()"
                                        >
                                            <template #invalid-feedback v-if="$v.formContainerData.GeneralInfo.$error">
                                                <div class='text-danger' v-if="!$v.formContainerData.GeneralInfo.onlyAlphanumeric3">{{$t('label.onlyAlphanumeric')}}</div>
                                                <div class='text-danger' v-if="!$v.formContainerData.GeneralInfo.maxLength">{{$t('label.max')+$v.formContainerData.GeneralInfo.$params.maxLength.max+$t('label.chars')}} </div>
                                            </template>
                                        </CInput>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.DescriptionOfGoods,'DescriptionOfGoods')"
                                            :color="alertType($v.formContainerData.DescriptionOfGoods,'DescriptionOfGoods')"
                                            class="toatsAlert"
                                            
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.DescriptionOfGoods,'DescriptionOfGoods') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.DescriptionOfGoods,'DescriptionOfGoods')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <CInput
                                            size="sm"
                                            
                                            label="DESCRIPTION OF GOODS"
                                            :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                            v-model="$v.formContainerData.DescriptionOfGoods.$model"
                                            :addInputClasses="alertasInputs($v.formContainerData.DescriptionOfGoods,'DescriptionOfGoods')"
                                            @focus="$v.formContainerData.DescriptionOfGoods.$touch()"
                                        >
                                            <template #invalid-feedback v-if="$v.formContainerData.DescriptionOfGoods.$error">
                                                <div class='text-danger' v-if="!$v.formContainerData.DescriptionOfGoods.onlyAlphanumeric3">{{$t('label.onlyAlphanumeric')}}</div>
                                                <div class='text-danger' v-if="!$v.formContainerData.DescriptionOfGoods.maxLength">{{$t('label.max')+$v.formContainerData.DescriptionOfGoods.$params.maxLength.max+$t('label.chars')}} </div>
                                            </template>
                                        </CInput>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.BookingNumber,'BookingNumber')"
                                            :color="alertType($v.formContainerData.BookingNumber,'BookingNumber')"
                                            class="toatsAlert"
                                            
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.BookingNumber,'BookingNumber') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.BookingNumber,'BookingNumber')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <CInput
                                            size="sm"
                                            
                                            label="BOOKING NUMBER"
                                            :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                            v-model="$v.formContainerData.BookingNumber.$model"
                                            :addInputClasses="alertasInputs($v.formContainerData.BookingNumber,'BookingNumber')"
                                            @focus="$v.formContainerData.BookingNumber.$touch()"
                                        >
                                            <template #invalid-feedback v-if="$v.formContainerData.BookingNumber.$error">
                                                <div class='text-danger' v-if="!$v.formContainerData.BookingNumber.onlyAlphanumeric3">{{$t('label.onlyAlphanumeric')}}</div>
                                                <div class='text-danger' v-if="!$v.formContainerData.BookingNumber.maxLength">{{$t('label.max')+$v.formContainerData.BookingNumber.$params.maxLength.max+$t('label.chars')}} </div>
                                            </template>
                                        </CInput>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.Consignee,'Consignee')"
                                            :color="alertType($v.formContainerData.Consignee,'Consignee')"
                                            class="toatsAlert"
                                            
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.Consignee,'Consignee') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.Consignee,'Consignee')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <CInput
                                            size="sm"
                                            
                                            label="CONSIGNEE"
                                            :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                            v-model="$v.formContainerData.Consignee.$model"
                                            :addInputClasses="alertasInputs($v.formContainerData.Consignee,'Consignee')"
                                            @focus="$v.formContainerData.Consignee.$touch()"
                                        >
                                            <template #invalid-feedback v-if="$v.formContainerData.Consignee.$error">
                                                <div class='text-danger' v-if="!$v.formContainerData.Consignee.minLength">{{$t('label.min')+$v.formContainerData.Consignee.$params.minLength.min+$t('label.chars')}} </div>
                                                <div class='text-danger' v-if="!$v.formContainerData.Consignee.maxLength">{{$t('label.max')+$v.formContainerData.Consignee.$params.maxLength.max+$t('label.chars')}} </div>
                                            </template>
                                        </CInput>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.ShipperOwn,'ShipperOwn')"
                                            :color="alertType($v.formContainerData.ShipperOwn,'ShipperOwn')"
                                            class="toatsAlert"
                                            
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.ShipperOwn,'ShipperOwn') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.ShipperOwn,'ShipperOwn')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <CInput
                                            size="sm"
                                            
                                            label="SHIPPER OWN"
                                            :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                            v-model="$v.formContainerData.ShipperOwn.$model"
                                            :addInputClasses="alertasInputs($v.formContainerData.ShipperOwn,'ShipperOwn')"
                                            @focus="$v.formContainerData.ShipperOwn.$touch()"
                                        >
                                            <template #invalid-feedback v-if="$v.formContainerData.ShipperOwn.$error">
                                                <div class='text-danger' v-if="!$v.formContainerData.ShipperOwn.minLength">{{$t('label.min')+$v.formContainerData.ShipperOwn.$params.minLength.min+$t('label.chars')}} </div>
                                                <div class='text-danger' v-if="!$v.formContainerData.ShipperOwn.maxLength">{{$t('label.max')+$v.formContainerData.ShipperOwn.$params.maxLength.max+$t('label.chars')}} </div>
                                            </template>
                                        </CInput>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.Shipper,'Shipper')"
                                            :color="alertType($v.formContainerData.Shipper,'Shipper')"
                                            class="toatsAlert"
                                            
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.Shipper,'Shipper') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.Shipper,'Shipper')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <CInput
                                            size="sm"
                                            
                                            label="SHIPPER"
                                            :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                            v-model="$v.formContainerData.Shipper.$model"
                                            :addInputClasses="alertasInputs($v.formContainerData.Shipper,'Shipper')"
                                            @focus="$v.formContainerData.Shipper.$touch()"
                                        >
                                            <template #invalid-feedback v-if="$v.formContainerData.Shipper.$error">
                                                <div class='text-danger' v-if="!$v.formContainerData.Shipper.minLength">{{$t('label.min')+$v.formContainerData.Shipper.$params.minLength.min+$t('label.chars')}} </div>
                                                <div class='text-danger' v-if="!$v.formContainerData.Shipper.maxLength">{{$t('label.max')+$v.formContainerData.Shipper.$params.maxLength.max+$t('label.chars')}} </div>
                                            </template>
                                        </CInput>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.BlNo,'BlNo')"
                                            :color="alertType($v.formContainerData.BlNo,'BlNo')"
                                            class="toatsAlert"
                                            
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.BlNo,'BlNo') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.BlNo,'BlNo')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <CInput
                                            size="sm"
                                            
                                            label="BL No"
                                            :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                            v-model="$v.formContainerData.BlNo.$model"
                                            :addInputClasses="alertasInputs($v.formContainerData.BlNo,'BlNo')"
                                            @focus="$v.formContainerData.BlNo.$touch()"
                                        >
                                            <template #invalid-feedback v-if="$v.formContainerData.BlNo.$error">
                                                <div class='text-danger' v-if="!$v.formContainerData.BlNo.maxLength">{{$t('label.max')+$v.formContainerData.BlNo.$params.maxLength.max+$t('label.chars')}} </div>
                                            </template>
                                        </CInput>
                                    </CCol>
                                    <CCol sm="12">
                                        <CAlert 
                                            :show="alertContent($v.formContainerData.Dua,'Dua')"
                                            :color="alertType($v.formContainerData.Dua,'Dua')"
                                            class="toatsAlert"
                                            
                                        >
                                            <div class="d-flex bd-highlight align-items-center">
                                                <div class="p-2 bd-highlight">
                                                    <CIcon class="icon-toast" :name="alertType($v.formContainerData.Dua,'Dua') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                </div> 
                                                <div class="p-2 bd-highlight flex-grow-1">
                                                    <b>&nbsp;{{alertMessage($v.formContainerData.Dua,'Dua')}}</b>
                                                </div>
                                            </div>
                                        </CAlert>
                                        <CInput
                                            size="sm"
                                            
                                            label="DUA"
                                            :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                            v-model="$v.formContainerData.Dua.$model"
                                            :addInputClasses="alertasInputs($v.formContainerData.Dua,'Dua')"
                                            @focus="$v.formContainerData.Dua.$touch()"
                                        >
                                            <template #invalid-feedback v-if="$v.formContainerData.Dua.$error">
                                                <div class='text-danger' v-if="!$v.formContainerData.Dua.onlyAlphanumeric3">{{$t('label.onlyAlphanumeric')}}</div>
                                                <div class='text-danger' v-if="!$v.formContainerData.Dua.maxLength">{{$t('label.max')+$v.formContainerData.Dua.$params.maxLength.max+$t('label.chars')}} </div>
                                            </template>
                                        </CInput>
                                    </CCol>
                                </CRow>
                            </CCardBody>
                        </CCard>
                    </CCol>
                    <CCol sm="4" style="margin:0px;padding:0px;padding-right: 10px">
                        <CRow>
                            <CCol sm="12" style="margin-bottom:2px;">
                                <CCard class="content-iso">
                                    <CCardTitle tag="h6">
                                        <div class="title-card iso-title">
                                            <CIcon name="cil-border-all"/>&nbsp;ISO INFO
                                        </div>
                                    </CCardTitle>
                                    <CCardBody>
                                        <CRow>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.IsoCode,'IsoCode')"
                                                    :color="alertType($v.formContainerData.IsoCode,'IsoCode')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.IsoCode,'IsoCode') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.IsoCode,'IsoCode')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <div class="form-group row" style="margin-bottom:0px">
                                                    <label for="staticContainerType" class="col-sm-5 required col-form-label col-form-label-sm">CONTAINER TYPE</label>
                                                    <div class="col-sm-7">
                                                        <div class="row">
                                                            <div class="col" style="padding-left:8px;padding-right: 2px">
                                                                <CInput
                                                                    size="sm"
                                                                    disabled
                                                                    :value="formContainerData.IsoCode"
                                                                    :addInputClasses="alertasInputs($v.formContainerData.IsoCode,'IsoCode')"
                                                                    @focus="$v.formContainerData.IsoCode.$touch()"
                                                                >
                                                                    <template #invalid-feedback v-if="$v.formContainerData.IsoCode.$error">
                                                                        <div class='text-danger' v-if="!$v.formContainerData.IsoCode.required">{{$t('label.required')}}</div>
                                                                        <div class='text-danger' v-if="!$v.formContainerData.IsoCode.minLength">{{$t('label.min')+$v.formContainerData.IsoCode.$params.minLength.min+$t('label.chars')}} </div>
                                                                        <div class='text-danger' v-if="!$v.formContainerData.IsoCode.maxLength">{{$t('label.max')+$v.formContainerData.IsoCode.$params.maxLength.max+$t('label.chars')}} </div>
                                                                    </template>
                                                                </CInput>
                                                            </div>
                                                            <div class="col" style="padding-left:0px">
                                                                <CSelect
                                                                    size="sm"
                                                                    v-model="formContainerData.MetadataId"
                                                                    :addInputClasses="{ 'is-invalid': $v.formContainerData.IsoCode.$error || $v.formContainerData.MetadataId.$error}"
                                                                    :value.sync="formContainerData.MetadataId"
                                                                    :options="optionsListContainerTypeAll"
                                                                    @change="checkContainerType"
                                                                    @focus="$v.formContainerData.MetadataId.$touch()"
                                                                >
                                                                </CSelect>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CCol>
                                            <CCol sm="12" class="especial-form">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.Lenght,'Lenght')"
                                                    :color="alertType($v.formContainerData.Lenght,'Lenght')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.Lenght,'Lenght') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.Lenght,'Lenght')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    id="lenghtInput"
                                                    label="LENGHT"
                                                    ref="lenghtInput"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    placeholder="0"
                                                    addLabelClasses="required"
                                                    v-money="money"
                                                    v-model="formContainerData.Lenght"
                                                    :value.sync="formContainerData.Lenght"
                                                    :maxlength="$v.formContainerData.Lenght.$params.maxLength.max"
                                                    @keypress="maxLength($event,$v.formContainerData.Lenght.$params.maxLength.max)"
                                                    @focus="$v.formContainerData.Lenght.$touch()"
                                                    :addInputClasses="alertasInputs($v.formContainerData.Lenght,'Lenght')"
                                                    required 
                                                >
                                                    <template #append>
                                                        <CSelect
                                                            size="sm"
                                                            :value.sync="formContainerData.UnitMeasureLenght"
                                                            @focus="$v.formContainerData.UnitMeasureLenght.$touch()"
                                                            v-model="formContainerData.UnitMeasureLenght"
                                                            :options="optionsListLongitud"
                                                            :addInputClasses="'not-border'"
                                                        />
                                                    </template>
                                                    <template #invalid-feedback>
                                                        <div v-if="$v.formContainerData.Lenght.$error">
                                                            <div class='text-danger' v-if="!$v.formContainerData.Lenght.required">{{$t('label.required')}}</div>
                                                            <div class='text-danger' v-if="!$v.formContainerData.Lenght.maxLength">{{$t('label.max')+$v.formContainerData.Lenght.$params.maxLength.max+$t('label.chars')}} </div>
                                                        </div>
                                                        <div v-if="$v.formContainerData.UnitMeasureLenght.$error">
                                                            <div class='text-danger' v-if="!$v.formContainerData.UnitMeasureLenght.required">{{$t('label.required')}}</div>
                                                        </div>
                                                    </template>
                                                ></CInput>
                                            </CCol>
                                            <CCol sm="12" class="especial-form">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.Height,'Height')"
                                                    :color="alertType($v.formContainerData.Height,'Height')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.Height,'Height') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.Height,'Height')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    label="HEIGHT"
                                                    ref="heightInput"
                                                    id="heightInput"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    placeholder="0"
                                                    addLabelClasses="required"
                                                    :maxlength="$v.formContainerData.Height.$params.maxLength.max"
                                                    @keypress="maxLength($event,$v.formContainerData.Height.$params.maxLength.max)"
                                                    v-money="money"
                                                    v-model="$v.formContainerData.Height.$model"
                                                    @focus="$v.formContainerData.Height.$touch()"
                                                    :addInputClasses="alertasInputs($v.formContainerData.Height,'Height')"
                                                    required 
                                                >
                                                    <template #append>
                                                        <CSelect
                                                            size="sm"
                                                            :value.sync="formContainerData.UnitMeasureHeigth"
                                                            @focus="$v.formContainerData.UnitMeasureHeigth.$touch()"
                                                            v-model="formContainerData.UnitMeasureHeigth"
                                                            :options="optionsListLongitud"
                                                            :addInputClasses="'not-border'"
                                                        />
                                                    </template>
                                                    <template #invalid-feedback>
                                                        <div v-if="$v.formContainerData.Height.$error">
                                                            <div class='text-danger' v-if="!$v.formContainerData.Height.required">{{$t('label.required')}}</div>
                                                            <div class='text-danger' v-if="!$v.formContainerData.Height.maxLength">{{$t('label.max')+$v.formContainerData.Height.$params.maxLength.max+$t('label.chars')}} </div>
                                                        </div>
                                                        <div v-if="$v.formContainerData.UnitMeasureHeigth.$error">
                                                            <div class='text-danger' v-if="!$v.formContainerData.UnitMeasureHeigth.required">{{$t('label.required')}}</div>
                                                        </div>
                                                    </template>
                                                ></CInput>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.TpCargoName,'TpCargoName')"
                                                    :color="alertType($v.formContainerData.TpCargoName,'TpCargoName')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.TpCargoName,'TpCargoName') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.TpCargoName,'TpCargoName')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    label="GROUP TYPE"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    :addInputClasses="alertasInputs($v.formContainerData.TpCargoName,'TpCargoName')"
                                                    disabled
                                                    :value="formContainerData.TpCargoName"
                                                />
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.TpCargoCode,'TpCargoCode')"
                                                    :color="alertType($v.formContainerData.TpCargoCode,'TpCargoCode')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.TpCargoCode,'TpCargoCode') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.TpCargoCode,'TpCargoCode')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    label="TYPE CARGO CODE"
                                                    :addInputClasses="alertasInputs($v.formContainerData.TpCargoCode,'TpCargoCode')"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    disabled
                                                    :value="formContainerData.TpCargoCode"
                                                />
                                            </CCol>
                                            <CCol sm="12" class="especial-form">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.Weigth,'Weigth')"
                                                    :color="alertType($v.formContainerData.Weigth,'Weigth')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.Weigth,'Weigth') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.Weigth,'Weigth')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    label="WEIGHT"
                                                    ref="weigthInput"
                                                    id="weigthInput"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    placeholder="0"
                                                    addLabelClasses="required"
                                                    :maxlength="$v.formContainerData.Weigth.$params.maxLength.max"
                                                    @keypress="maxLength($event,$v.formContainerData.Weigth.$params.maxLength.max)"
                                                    v-money="money"
                                                    v-model="$v.formContainerData.Weigth.$model"
                                                    @focus="$v.formContainerData.Weigth.$touch()"
                                                    :addInputClasses="alertasInputs($v.formContainerData.Weigth,'Weigth')"
                                                    required 
                                                >
                                                    <template #append>
                                                        <CSelect
                                                            size="sm"
                                                            :value.sync="formContainerData.UnitMeasureWeigth"
                                                            @focus="$v.formContainerData.UnitMeasureWeigth.$touch()"
                                                            v-model="formContainerData.UnitMeasureWeigth"
                                                            :options="optionsListWeight"
                                                            :addInputClasses="'not-border'"
                                                        />
                                                    </template>
                                                    <template #invalid-feedback>
                                                        <div v-if="$v.formContainerData.Weigth.$error" style="margin-bottom:10px;">
                                                            <div class='text-danger' v-if="!$v.formContainerData.Weigth.required">{{$t('label.required')}}</div>
                                                            <div class='text-danger' v-if="!$v.formContainerData.Weigth.maxLength">{{$t('label.max')+$v.formContainerData.Weigth.$params.maxLength.max+$t('label.chars')}}</div>
                                                            <div class='text-danger' v-if="!$v.formContainerData.Weigth.limit">{{$t('label.container_data.container_weight')+numberTotalWEIGHT}}</div>
                                                        </div>
                                                        <div v-if="$v.formContainerData.UnitMeasureWeigth.$error" style="margin-bottom:10px;">
                                                            <div class='text-danger' v-if="!$v.formContainerData.UnitMeasureWeigth.required">{{$t('label.required')}}</div>
                                                        </div>
                                                    </template>
                                                ></CInput>
                                            </CCol>
                                            <CCol sm="12" class="especial-form">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.Vgm,'Vgm')"
                                                    :color="alertType($v.formContainerData.Vgm,'Vgm')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.Vgm,'Vgm') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.Vgm,'Vgm')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    label="VGM"
                                                    ref="vgmInput"
                                                    id="vgmInput"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    placeholder="0"
                                                    :addLabelClasses="obligationVGM ? 'required' : ''"
                                                    :maxlength="$v.formContainerData.Vgm.$params.maxLength.max"
                                                    @keypress="maxLength($event,$v.formContainerData.Vgm.$params.maxLength.max)"
                                                    v-money="money"
                                                    v-model="$v.formContainerData.Vgm.$model"
                                                    @focus="$v.formContainerData.Vgm.$touch()"
                                                    :addInputClasses="alertasInputs($v.formContainerData.Vgm,'Vgm')"
                                                    required 
                                                >
                                                    <template #append>
                                                        <CSelect
                                                            size="sm"
                                                            :value.sync="formContainerData.UnitMeasureVgm"
                                                            @focus="$v.formContainerData.UnitMeasureVgm.$touch()"
                                                            v-model="formContainerData.UnitMeasureVgm"
                                                            :options="optionsListWeight"
                                                            :addInputClasses="'not-border'"
                                                        />
                                                    </template>
                                                    <template #invalid-feedback>
                                                        <div v-if="$v.formContainerData.Vgm.$error" style="margin-bottom:10px;">
                                                            <div class='text-danger' v-if="obligationVGM && !$v.formContainerData.Vgm.required">{{$t('label.required')}}</div>
                                                            <div class='text-danger' v-if="!$v.formContainerData.Vgm.maxLength">{{$t('label.max')+$v.formContainerData.Weigth.$params.maxLength.max+$t('label.chars')}}</div>
                                                            <div class='text-danger' v-if="!$v.formContainerData.Vgm.limit">{{$t('label.container_data.container_size')+numberTotalVGM}}</div>
                                                        </div>
                                                        <div v-if="$v.formContainerData.UnitMeasureVgm.$error" style="margin-bottom:10px;">
                                                            <div class='text-danger' v-if="!$v.formContainerData.UnitMeasureVgm.required">{{$t('label.required')}}</div>
                                                        </div>
                                                    </template>
                                                ></CInput>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.DescriptionType,'DescriptionType')"
                                                    :color="alertType($v.formContainerData.DescriptionType,'DescriptionType')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.DescriptionType,'DescriptionType') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.DescriptionType,'DescriptionType')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    label="DESCRIPTION"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    disabled
                                                    v-model="$v.formContainerData.DescriptionType.$model"
                                                    :addInputClasses="alertasInputs($v.formContainerData.DescriptionType,'DescriptionType')"
                                                    @focus="$v.formContainerData.DescriptionType.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="$v.formContainerData.DescriptionType.$error">
                                                        <div class='text-danger' v-if="!$v.formContainerData.DescriptionType.minLength">{{$t('label.min')+$v.formContainerData.DescriptionType.$params.minLength.min+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.DescriptionType.maxLength">{{$t('label.max')+$v.formContainerData.DescriptionType.$params.maxLength.max+$t('label.chars')}} </div>
                                                    </template>
                                                </CInput>
                                            </CCol>
                                        </CRow>
                                    </CCardBody>
                                </CCard>
                            </CCol>
                            <CCol sm="12" style="margin-bottom:2px;">
                                <CCard :class="formContainerData.PluggedIn ? 'content-reefer' : 'content-not-reefer'">
                                    <CCardTitle tag="h6">
                                        <div :class="formContainerData.PluggedIn ? 'title-card reefer-title' : 'title-card reefer-not-title'">
                                            <CIcon name="cil-speedometer"/>&nbsp;REEFER INFO
                                        </div>
                                    </CCardTitle>
                                    <CCardBody>
                                        <CRow>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.UnitMeasureRf,'UnitMeasureRf')"
                                                    :color="alertType($v.formContainerData.UnitMeasureRf,'UnitMeasureRf')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.UnitMeasureRf,'UnitMeasureRf') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.UnitMeasureRf,'UnitMeasureRf')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CSelect
                                                    size="sm"
                                                    label="MEASURE UNIT RF"
                                                    :disabled="!obligationRefeerDisabled"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    :addLabelClasses="formContainerData.PluggedIn ? 'required' : ''"
                                                    :addInputClasses="alertasInputs($v.formContainerData.UnitMeasureRf,'UnitMeasureRf')"
                                                    v-model="formContainerData.UnitMeasureRf"
                                                    :value.sync="formContainerData.UnitMeasureRf"
                                                    :options="optionsListrefeerAll"
                                                    @focus="$v.formContainerData.UnitMeasureRf.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="formContainerData.PluggedIn && $v.formContainerData.UnitMeasureRf.$error">
                                                        <div class='text-danger' v-if="!$v.formContainerData.UnitMeasureRf.required">{{$t('label.required')}}</div>
                                                    </template>
                                                </CSelect>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.ReeferSetting,'ReeferSetting')"
                                                    :color="alertType($v.formContainerData.ReeferSetting,'ReeferSetting')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.ReeferSetting,'ReeferSetting') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.ReeferSetting,'ReeferSetting')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    label="REEFER SETTING"
                                                    
                                                    :disabled="!obligationRefeerDisabled || !formContainerData.PluggedIn"
                                                    :addLabelClasses="formContainerData.PluggedIn ? 'required' : ''"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    v-model="$v.formContainerData.ReeferSetting.$model"
                                                    :addInputClasses="alertasInputs($v.formContainerData.ReeferSetting,'ReeferSetting')"
                                                    @focus="$v.formContainerData.ReeferSetting.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="$v.formContainerData.ReeferSetting.$error">
                                                        <div class='text-danger' v-if="formContainerData.PluggedIn && !$v.formContainerData.ReeferSetting.required">{{$t('label.required')}}</div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.ReeferSetting.minLength">{{$t('label.min')+$v.formContainerData.ReeferSetting.$params.minLength.min+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.ReeferSetting.maxLength">{{$t('label.max')+$v.formContainerData.ReeferSetting.$params.maxLength.max+$t('label.chars')}} </div>
                                                    </template>
                                                </CInput>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.MinimumRangeRf,'MinimumRangeRf')"
                                                    :color="alertType($v.formContainerData.MinimumRangeRf,'MinimumRangeRf')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.MinimumRangeRf,'MinimumRangeRf') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.MinimumRangeRf,'MinimumRangeRf')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    label="MINIMUM RANGE RF"
                                                    
                                                    :disabled="!obligationRefeerDisabled"
                                                    :addLabelClasses="formContainerData.PluggedIn ? 'required' : ''"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    v-model="$v.formContainerData.MinimumRangeRf.$model"
                                                    :addInputClasses="alertasInputs($v.formContainerData.MinimumRangeRf,'MinimumRangeRf')"
                                                    @focus="$v.formContainerData.MinimumRangeRf.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="$v.formContainerData.MinimumRangeRf.$error">
                                                        <div class='text-danger' v-if="formContainerData.PluggedIn && !$v.formContainerData.MinimumRangeRf.required">{{$t('label.required')}}</div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.MinimumRangeRf.minLength">{{$t('label.min')+$v.formContainerData.MinimumRangeRf.$params.minLength.min+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.MinimumRangeRf.maxLength">{{$t('label.max')+$v.formContainerData.MinimumRangeRf.$params.maxLength.max+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.MinimumRangeRf.metricsWSNotReq">{{$t('label.badFormat')}}</div>
                                                    </template>
                                                </CInput>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.MaximumRangeRf,'MaximumRangeRf')"
                                                    :color="alertType($v.formContainerData.MaximumRangeRf,'MaximumRangeRf')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.MaximumRangeRf,'MaximumRangeRf') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.MaximumRangeRf,'MaximumRangeRf')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    label="MAXIMUM RANGE RF"
                                                    
                                                    :disabled="!obligationRefeerDisabled"
                                                    :addLabelClasses="formContainerData.PluggedIn ? 'required' : ''"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    v-model="$v.formContainerData.MaximumRangeRf.$model"
                                                    :addInputClasses="alertasInputs($v.formContainerData.MaximumRangeRf,'MaximumRangeRf')"
                                                    @focus="$v.formContainerData.MaximumRangeRf.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="$v.formContainerData.MaximumRangeRf.$error">
                                                        <div class='text-danger' v-if="formContainerData.PluggedIn && !$v.formContainerData.MaximumRangeRf.required">{{$t('label.required')}}</div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.MaximumRangeRf.minLength">{{$t('label.min')+$v.formContainerData.MaximumRangeRf.$params.minLength.min+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.MaximumRangeRf.maxLength">{{$t('label.max')+$v.formContainerData.MaximumRangeRf.$params.maxLength.max+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.MaximumRangeRf.metricsWSNotReq">{{$t('label.badFormat')}}</div>
                                                    </template>
                                                </CInput>
                                            </CCol>
                                            <CCol sm="12">
                                                <div class="form-group row" style="margin-bottom:0px">
                                                    <label for="staticCheck" class="col-sm-5 col-form-label col-form-label-sm">PLUGGED IN?</label>
                                                    <div class="col-sm-7 d-flex align-items-center">
                                                        <CSwitch
                                                            color="info"
                                                            :disabled="!obligationRefeerDisabled"
                                                            size="sm"
                                                            type="checkbox"
                                                            labelOn="ON" 
                                                            labelOff="OFF"
                                                            :checked.sync="formContainerData.PluggedIn"
                                                        />
                                                    </div>
                                                </div>
                                            </CCol>
                                        </CRow>
                                    </CCardBody>
                                </CCard>
                            </CCol>
                            <CCol sm="12">
                                 <CCard class="content-oversize">
                                    <CCardTitle tag="h6">
                                        <div class="title-card oversize-title">
                                            <CIcon name="cil-view-stream"/>&nbsp;OVERSIZE INFO
                                        </div>
                                    </CCardTitle>
                                    <CCardBody>
                                        <CRow>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.UnitMeasureDim,'UnitMeasureDim')"
                                                    :color="alertType($v.formContainerData.UnitMeasureDim,'UnitMeasureDim')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.UnitMeasureDim,'UnitMeasureDim') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.UnitMeasureDim,'UnitMeasureDim')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CSelect
                                                    size="sm"
                                                    label="MEASURE UNIT DIM "
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    disabled
                                                    v-model="formContainerData.UnitMeasureDim"
                                                    :value.sync="formContainerData.UnitMeasureDim"
                                                    :options="optionsListLongitud"
                                                    :addInputClasses="alertasInputs($v.formContainerData.UnitMeasureDim,'UnitMeasureDim')"
                                                    @focus="$v.formContainerData.UnitMeasureDim.$touch()"
                                                >
                                                </CSelect>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.DimFront,'DimFront')"
                                                    :color="alertType($v.formContainerData.DimFront,'DimFront')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.DimFront,'DimFront') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.DimFront,'DimFront')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    :disabled="oversizeInfoDisabled"
                                                    ref="DimFrontInput"
                                                    label="DIM FRONT"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    v-money="money"
                                                    :maxlength="$v.formContainerData.DimFront.$params.maxLength.max"
                                                    @keypress="maxLength($event,$v.formContainerData.DimFront.$params.maxLength.max)"
                                                    v-model.lazy="$v.formContainerData.DimFront.$model"
                                                    :addInputClasses="alertasInputs($v.formContainerData.DimFront,'DimFront')"
                                                    @focus="$v.formContainerData.DimFront.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="$v.formContainerData.DimFront.$error">
                                                        <div class='text-danger' v-if="!$v.formContainerData.DimFront.SoloDecimales">{{$t('label.onlyNumber')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.DimFront.maxLength">{{$t('label.max')+$v.formContainerData.DimFront.$params.maxLength.max+$t('label.chars')}}</div>
                                                    </template>
                                                </CInput>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.DimBack,'DimBack')"
                                                    :color="alertType($v.formContainerData.DimBack,'DimBack')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.DimBack,'DimBack') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.DimBack,'DimBack')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    ref="DimBackInput"
                                                    :disabled="oversizeInfoDisabled"
                                                    label="DIM BACK"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    v-money="money"
                                                    :maxlength="$v.formContainerData.DimBack.$params.maxLength.max"
                                                    @keypress="maxLength($event,$v.formContainerData.DimBack.$params.maxLength.max)"
                                                    v-model.lazy="$v.formContainerData.DimBack.$model"
                                                    :addInputClasses="alertasInputs($v.formContainerData.DimBack,'DimBack')"
                                                    @focus="$v.formContainerData.DimBack.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="$v.formContainerData.DimBack.$error">
                                                        <div class='text-danger' v-if="!$v.formContainerData.DimBack.SoloDecimales">{{$t('label.onlyNumber')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.DimBack.maxLength">{{$t('label.max')+$v.formContainerData.DimBack.$params.maxLength.max+$t('label.chars')}}</div>
                                                    </template>
                                                </CInput>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.DimLeft,'DimLeft')"
                                                    :color="alertType($v.formContainerData.DimLeft,'DimLeft')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.DimLeft,'DimLeft') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.DimLeft,'DimLeft')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    :disabled="oversizeInfoDisabled"
                                                    ref="DimLeftInput"
                                                    label="DIM LEFT"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    v-money="money"
                                                    :maxlength="$v.formContainerData.DimLeft.$params.maxLength.max"
                                                    @keypress="maxLength($event,$v.formContainerData.DimLeft.$params.maxLength.max)"
                                                    v-model.lazy="$v.formContainerData.DimLeft.$model"
                                                    :addInputClasses="alertasInputs($v.formContainerData.DimLeft,'DimLeft')"
                                                    @focus="$v.formContainerData.DimLeft.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="$v.formContainerData.DimLeft.$error">
                                                        <div class='text-danger' v-if="!$v.formContainerData.DimLeft.SoloDecimales">{{$t('label.onlyNumber')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.DimLeft.maxLength">{{$t('label.max')+$v.formContainerData.DimLeft.$params.maxLength.max+$t('label.chars')}}</div>
                                                    </template>
                                                </CInput>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.DimRight,'DimRight')"
                                                    :color="alertType($v.formContainerData.DimRight,'DimRight')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.DimRight,'DimRight') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.DimRight,'DimRight')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    :disabled="oversizeInfoDisabled"
                                                    ref="DimRightInput"
                                                    label="DIM RIGHT"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    v-money="money"
                                                    :maxlength="$v.formContainerData.DimRight.$params.maxLength.max"
                                                    @keypress="maxLength($event,$v.formContainerData.DimRight.$params.maxLength.max)"
                                                    v-model.lazy="$v.formContainerData.DimRight.$model"
                                                    :addInputClasses="alertasInputs($v.formContainerData.DimRight,'DimRight')"
                                                    @focus="$v.formContainerData.DimRight.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="$v.formContainerData.DimRight.$error">
                                                        <div class='text-danger' v-if="!$v.formContainerData.DimRight.SoloDecimales">{{$t('label.onlyNumber')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.DimRight.maxLength">{{$t('label.max')+$v.formContainerData.DimLeft.$params.maxLength.max+$t('label.chars')}}</div>
                                                    </template>
                                                </CInput>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.DimTop,'DimTop')"
                                                    :color="alertType($v.formContainerData.DimTop,'DimTop')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.DimTop,'DimTop') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.DimTop,'DimTop')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    :disabled="oversizeInfoDisabled"
                                                    ref="DimTopInput"
                                                    label="DIM TOP"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    v-money="money"
                                                    :maxlength="$v.formContainerData.DimTop.$params.maxLength.max"
                                                    @keypress="maxLength($event,$v.formContainerData.DimTop.$params.maxLength.max)"
                                                    v-model.lazy="$v.formContainerData.DimTop.$model"
                                                    :addInputClasses="alertasInputs($v.formContainerData.DimTop,'DimTop')"
                                                    @focus="$v.formContainerData.DimTop.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="$v.formContainerData.DimTop.$error">
                                                        <div class='text-danger' v-if="!$v.formContainerData.DimTop.SoloDecimales">{{$t('label.onlyNumber')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.DimTop.maxLength">{{$t('label.max')+$v.formContainerData.DimTop.$params.maxLength.max+$t('label.chars')}}</div>
                                                    </template>
                                                </CInput>
                                            </CCol>
                                        </CRow>
                                    </CCardBody>
                                </CCard>
                            </CCol>
                        </CRow>
                    </CCol>
                    <CCol sm="4" style="margin:0px;padding:0px;">
                        <CRow>
                            <CCol sm="12" style="margin-bottom:2px;">
                                <CCard class="content-dangerous">
                                    <CCardTitle tag="h6">
                                        <div class="title-card dangerous-title">
                                            <CIcon name="cil-warning"/>&nbsp;DANGEROUS INFO
                                        </div>
                                    </CCardTitle>
                                    <CCardBody>
                                        <CRow>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.ImdgCode,'ImdgCode')"
                                                    :color="alertType($v.formContainerData.ImdgCode,'ImdgCode')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.ImdgCode,'ImdgCode') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.ImdgCode,'ImdgCode')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <div class="form-group row" style="margin-bottom:0px">
                                                    <label for="staticLoad" class="col-sm-5 col-form-label col-form-label-sm">IMDG CODE</label>
                                                    <div class="col-sm-7">
                                                        <div class="row">
                                                            <div class="col" style="padding-left:8px;padding-right: 2px">
                                                                <CInput
                                                                    size="sm"
                                                                    disabled
                                                                    :value="formContainerData.ImdgCode"
                                                                    :addInputClasses="alertasInputs($v.formContainerData.ImdgCode,'ImdgCode')"
                                                                    @focus="$v.formContainerData.ImdgCode.$touch()"
                                                                >
                                                                    <template #invalid-feedback v-if="$v.formContainerData.ImdgCode.$error">
                                                                        <div class='text-danger' v-if="!$v.formContainerData.ImdgCode.minLength">{{$t('label.min')+$v.formContainerData.ImdgCode.$params.minLength.min+$t('label.chars')}} </div>
                                                                        <div class='text-danger' v-if="!$v.formContainerData.ImdgCode.maxLength">{{$t('label.max')+$v.formContainerData.ImdgCode.$params.maxLength.max+$t('label.chars')}} </div>
                                                                    </template>
                                                                </CInput>
                                                            </div>
                                                            <div class="col" style="padding-left:0px">
                                                                <CSelect
                                                                    size="sm"
                                                                    v-model="formContainerData.ImdgClassId"
                                                                    :addInputClasses="{ 'is-invalid': $v.formContainerData.ImdgCode.$error || $v.formContainerData.ImdgClassId.$error}"
                                                                    :value.sync="formContainerData.ImdgClassId"
                                                                    :options="optionsListHazardCodeAll"
                                                                    @change="checkImdgCode"
                                                                    @focus="$v.formContainerData.ImdgClassId.$touch()"
                                                                >
                                                                </CSelect>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.ImdgClassCode,'ImdgClassCode')"
                                                    :color="alertType($v.formContainerData.ImdgClassCode,'ImdgClassCode')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.ImdgClassCode,'ImdgClassCode') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.ImdgClassCode,'ImdgClassCode')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <div class="form-group row" style="margin-bottom:0px">
                                                    <label for="staticLoad" :class="'col-sm-5 col-form-label col-form-label-sm '+(obligationImdgCode ? 'required' : '')">HAZARD CLASS</label>
                                                    <div class="col-sm-7">
                                                        <div class="row">
                                                            <div class="col" style="padding-left:8px;padding-right: 2px">
                                                                <CInput
                                                                    size="sm"
                                                                    disabled
                                                                    :value="formContainerData.ImdgClassCode"
                                                                    :addInputClasses="alertasInputs($v.formContainerData.ImdgClassCode,'ImdgClassCode')"
                                                                    @focus="$v.formContainerData.ImdgClassCode.$touch()"
                                                                >
                                                                    <template #invalid-feedback v-if="$v.formContainerData.ImdgClassCode.$error">
                                                                        <div class='text-danger' v-if="obligationImdgCode && !$v.formContainerData.ImdgClassCode.required">{{$t('label.required')}}</div>
                                                                        <div class='text-danger' v-if="!$v.formContainerData.ImdgClassCode.minLength">{{$t('label.min')+$v.formContainerData.ImdgClassCode.$params.minLength.min+$t('label.chars')}} </div>
                                                                        <div class='text-danger' v-if="!$v.formContainerData.ImdgClassCode.maxLength">{{$t('label.max')+$v.formContainerData.ImdgClassCode.$params.maxLength.max+$t('label.chars')}} </div>
                                                                    </template>
                                                                </CInput>
                                                            </div>
                                                            <div class="col" style="padding-left:0px">
                                                                <CSelect
                                                                    size="sm"
                                                                    v-model="formContainerData.ImdgId"
                                                                    :addInputClasses="{ 'is-invalid': $v.formContainerData.ImdgClassCode.$error || $v.formContainerData.ImdgId.$error}"
                                                                    :value.sync="formContainerData.ImdgId"
                                                                    :options="optionsListHazardClassAll"
                                                                    @change="checkImdgClass"
                                                                    @focus="$v.formContainerData.ImdgId.$touch()"
                                                                >
                                                                </CSelect>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.ImgdPageNo,'ImgdPageNo')"
                                                    :color="alertType($v.formContainerData.ImgdPageNo,'ImgdPageNo')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.ImgdPageNo,'ImgdPageNo') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.ImgdPageNo,'ImgdPageNo')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    
                                                    label="IMDG PAGE NO"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    v-model="$v.formContainerData.ImgdPageNo.$model"
                                                    :addInputClasses="alertasInputs($v.formContainerData.ImgdPageNo,'ImgdPageNo')"
                                                    @focus="$v.formContainerData.ImgdPageNo.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="$v.formContainerData.ImgdPageNo.$error">
                                                        <div class='text-danger' v-if="!$v.formContainerData.ImgdPageNo.minLength">{{$t('label.min')+$v.formContainerData.ImgdPageNo.$params.minLength.min+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.ImgdPageNo.maxLength">{{$t('label.max')+$v.formContainerData.ImgdPageNo.$params.maxLength.max+$t('label.chars')}} </div>
                                                    </template>
                                                </CInput>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.UnNumber,'UnNumber')"
                                                    :color="alertType($v.formContainerData.UnNumber,'UnNumber')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.UnNumber,'UnNumber') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.UnNumber,'UnNumber')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    
                                                    label="UN NUMBER"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    v-model="$v.formContainerData.UnNumber.$model"
                                                    :addInputClasses="alertasInputs($v.formContainerData.UnNumber,'UnNumber')"
                                                    @focus="$v.formContainerData.UnNumber.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="$v.formContainerData.UnNumber.$error">
                                                        <div class='text-danger' v-if="!$v.formContainerData.UnNumber.minLength">{{$t('label.min')+$v.formContainerData.UnNumber.$params.minLength.min+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.UnNumber.maxLength">{{$t('label.max')+$v.formContainerData.UnNumber.$params.maxLength.max+$t('label.chars')}} </div>
                                                    </template>
                                                </CInput>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.FlashPoint,'FlashPoint')"
                                                    :color="alertType($v.formContainerData.FlashPoint,'FlashPoint')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.FlashPoint,'FlashPoint') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.FlashPoint,'FlashPoint')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    
                                                    label="FLASH POINT"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    v-model="$v.formContainerData.FlashPoint.$model"
                                                    :addInputClasses="alertasInputs($v.formContainerData.FlashPoint,'FlashPoint')"
                                                    @focus="$v.formContainerData.FlashPoint.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="$v.formContainerData.FlashPoint.$error">
                                                        <div class='text-danger' v-if="!$v.formContainerData.FlashPoint.minLength">{{$t('label.min')+$v.formContainerData.FlashPoint.$params.minLength.min+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.FlashPoint.maxLength">{{$t('label.max')+$v.formContainerData.FlashPoint.$params.maxLength.max+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.FlashPoint.metricsWSNotReq">{{$t('label.badFormat')}}</div>
                                                    </template>
                                                </CInput>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.UnitMeasureFlashPoint,'UnitMeasureFlashPoint')"
                                                    :color="alertType($v.formContainerData.UnitMeasureFlashPoint,'UnitMeasureFlashPoint')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.UnitMeasureFlashPoint,'UnitMeasureFlashPoint') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.UnitMeasureFlashPoint,'UnitMeasureFlashPoint')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CSelect
                                                    size="sm"
                                                    label="FLASH POINT MEASURE UNITS"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    :addLabelClasses="obligationUnidadFlashPoint ? 'required' : ''"
                                                    v-model="formContainerData.UnitMeasureFlashPoint"
                                                    :value.sync="formContainerData.UnitMeasureFlashPoint"
                                                    :addInputClasses="alertasInputs($v.formContainerData.UnitMeasureFlashPoint,'UnitMeasureFlashPoint')"
                                                    :options="optionsListrefeerAll"
                                                    @focus="$v.formContainerData.UnitMeasureFlashPoint.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="$v.formContainerData.UnitMeasureFlashPoint.$error">
                                                        <div class='text-danger' v-if="obligationUnidadFlashPoint && !$v.formContainerData.UnitMeasureFlashPoint.required">{{$t('label.required')}}</div>
                                                    </template>
                                                </CSelect>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.PackagingGroupCode,'PackagingGroupCode')"
                                                    :color="alertType($v.formContainerData.PackagingGroupCode,'PackagingGroupCode')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.PackagingGroupCode,'PackagingGroupCode') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.PackagingGroupCode,'PackagingGroupCode')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <div class="form-group row" style="margin-bottom:0px">
                                                    <label for="staticLoad" class="col-sm-5 col-form-label col-form-label-sm">PACKAGING GROUP</label>
                                                    <div class="col-sm-7">
                                                        <div class="row">
                                                            <div class="col" style="padding-left:8px;padding-right: 2px">
                                                                <CInput
                                                                    size="sm"
                                                                    disabled
                                                                    :value="formContainerData.PackagingGroupCode"
                                                                    :addInputClasses="alertasInputs($v.formContainerData.PackagingGroupCode,'PackagingGroupCode')"
                                                                    @focus="$v.formContainerData.PackagingGroupCode.$touch()"
                                                                >
                                                                    <template #invalid-feedback v-if="$v.formContainerData.PackagingGroupCode.$error">
                                                                        <div class='text-danger' v-if="!$v.formContainerData.PackagingGroupCode.minLength">{{$t('label.min')+$v.formContainerData.PackagingGroupCode.$params.minLength.min+$t('label.chars')}} </div>
                                                                        <div class='text-danger' v-if="!$v.formContainerData.PackagingGroupCode.maxLength">{{$t('label.max')+$v.formContainerData.PackagingGroupCode.$params.maxLength.max+$t('label.chars')}} </div>
                                                                    </template>
                                                                </CInput>
                                                            </div>
                                                            <div class="col" style="padding-left:0px">
                                                                <CSelect
                                                                    size="sm"
                                                                    v-model="formContainerData.PackagingGroupId"
                                                                    :addInputClasses="{ 'is-invalid': $v.formContainerData.PackagingGroupCode.$error || $v.formContainerData.PackagingGroupId.$error}"
                                                                    :value.sync="formContainerData.PackagingGroupId"
                                                                    :options="optionsListPackagingGroupAll"
                                                                    @change="checkPackagingGroupCode"
                                                                    @focus="$v.formContainerData.ImdgId.$touch()"
                                                                >
                                                                </CSelect>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.EmergencySheduleNo,'EmergencySheduleNo')"
                                                    :color="alertType($v.formContainerData.EmergencySheduleNo,'EmergencySheduleNo')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.EmergencySheduleNo,'EmergencySheduleNo') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.EmergencySheduleNo,'EmergencySheduleNo')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    
                                                    label="EMERGENCY SCHEDULE NO"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    v-model="$v.formContainerData.EmergencySheduleNo.$model"
                                                    :addInputClasses="alertasInputs($v.formContainerData.EmergencySheduleNo,'EmergencySheduleNo')"
                                                    @focus="$v.formContainerData.EmergencySheduleNo.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="$v.formContainerData.EmergencySheduleNo.$error">
                                                        <div class='text-danger' v-if="!$v.formContainerData.EmergencySheduleNo.onlyAlphanumeric3">{{$t('label.onlyAlphanumeric')}}</div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.EmergencySheduleNo.minLength">{{$t('label.min')+$v.formContainerData.EmergencySheduleNo.$params.minLength.min+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.EmergencySheduleNo.maxLength">{{$t('label.max')+$v.formContainerData.EmergencySheduleNo.$params.maxLength.max+$t('label.chars')}} </div>
                                                    </template>
                                                </CInput>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.MedicalGuide,'MedicalGuide')"
                                                    :color="alertType($v.formContainerData.MedicalGuide,'MedicalGuide')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.MedicalGuide,'MedicalGuide') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.MedicalGuide,'MedicalGuide')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    
                                                    label="MEDICAL FIRST AID GUIDE NO"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    v-model="$v.formContainerData.MedicalGuide.$model"
                                                    :addInputClasses="alertasInputs($v.formContainerData.MedicalGuide,'MedicalGuide')"
                                                    @focus="$v.formContainerData.MedicalGuide.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="$v.formContainerData.MedicalGuide.$error">
                                                        <div class='text-danger' v-if="!$v.formContainerData.MedicalGuide.onlyAlphanumeric3">{{$t('label.onlyAlphanumeric')}}</div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.MedicalGuide.minLength">{{$t('label.min')+$v.formContainerData.MedicalGuide.$params.minLength.min+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.MedicalGuide.maxLength">{{$t('label.max')+$v.formContainerData.MedicalGuide.$params.maxLength.max+$t('label.chars')}} </div>
                                                    </template>
                                                </CInput>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.ImdgCodePlaceCardUpper,'ImdgCodePlaceCardUpper')"
                                                    :color="alertType($v.formContainerData.ImdgCodePlaceCardUpper,'ImdgCodePlaceCardUpper')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.ImdgCodePlaceCardUpper,'ImdgCodePlaceCardUpper') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.ImdgCodePlaceCardUpper,'ImdgCodePlaceCardUpper')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    
                                                    label=" ORANGE HAZARD PLACE CARD UPPER PART IDENTIFIER"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    v-model="$v.formContainerData.ImdgCodePlaceCardUpper.$model"
                                                    :addInputClasses="alertasInputs($v.formContainerData.ImdgCodePlaceCardUpper,'ImdgCodePlaceCardUpper')"
                                                    @focus="$v.formContainerData.ImdgCodePlaceCardUpper.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="$v.formContainerData.ImdgCodePlaceCardUpper.$error">
                                                        <div class='text-danger' v-if="!$v.formContainerData.ImdgCodePlaceCardUpper.minLength">{{$t('label.min')+$v.formContainerData.ImdgCodePlaceCardUpper.$params.minLength.min+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.ImdgCodePlaceCardUpper.maxLength">{{$t('label.max')+$v.formContainerData.ImdgCodePlaceCardUpper.$params.maxLength.max+$t('label.chars')}} </div>
                                                    </template>
                                                </CInput>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.ImdgCodePlaceCardLower,'ImdgCodePlaceCardLower')"
                                                    :color="alertType($v.formContainerData.ImdgCodePlaceCardLower,'ImdgCodePlaceCardLower')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.ImdgCodePlaceCardLower,'ImdgCodePlaceCardLower') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.ImdgCodePlaceCardLower,'ImdgCodePlaceCardLower')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    
                                                    label=" ORANGE HAZARD PLACE CARD LOWER PART IDENTIFIER"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    v-model="$v.formContainerData.ImdgCodePlaceCardLower.$model"
                                                    :addInputClasses="alertasInputs($v.formContainerData.ImdgCodePlaceCardLower,'ImdgCodePlaceCardLower')"
                                                    @focus="$v.formContainerData.ImdgCodePlaceCardLower.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="$v.formContainerData.ImdgCodePlaceCardLower.$error">
                                                        <div class='text-danger' v-if="!$v.formContainerData.ImdgCodePlaceCardLower.minLength">{{$t('label.min')+$v.formContainerData.ImdgCodePlaceCardLower.$params.minLength.min+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.ImdgCodePlaceCardLower.maxLength">{{$t('label.max')+$v.formContainerData.ImdgCodePlaceCardLower.$params.maxLength.max+$t('label.chars')}} </div>
                                                    </template>
                                                </CInput>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.DangerousLabel1,'DangerousLabel1')"
                                                    :color="alertType($v.formContainerData.DangerousLabel1,'DangerousLabel1')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.DangerousLabel1,'DangerousLabel1') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.DangerousLabel1,'DangerousLabel1')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    
                                                    label="DANGEROUS GOOD LABEL 1"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    v-model="$v.formContainerData.DangerousLabel1.$model"
                                                    :addInputClasses="alertasInputs($v.formContainerData.DangerousLabel1,'DangerousLabel1')"
                                                    @focus="$v.formContainerData.DangerousLabel1.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="$v.formContainerData.DangerousLabel1.$error">
                                                        <div class='text-danger' v-if="!$v.formContainerData.DangerousLabel1.minLength">{{$t('label.min')+$v.formContainerData.DangerousLabel1.$params.minLength.min+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.DangerousLabel1.maxLength">{{$t('label.max')+$v.formContainerData.DangerousLabel1.$params.maxLength.max+$t('label.chars')}} </div>
                                                    </template>
                                                </CInput>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.DangerousLabel2,'DangerousLabel2')"
                                                    :color="alertType($v.formContainerData.DangerousLabel2,'DangerousLabel2')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.DangerousLabel2,'DangerousLabel2') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.DangerousLabel2,'DangerousLabel2')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    
                                                    label="DANGEROUS GOOD LABEL 2"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    v-model="$v.formContainerData.DangerousLabel2.$model"
                                                    :addInputClasses="alertasInputs($v.formContainerData.DangerousLabel2,'DangerousLabel2')"
                                                    @focus="$v.formContainerData.DangerousLabel2.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="$v.formContainerData.DangerousLabel2.$error">
                                                        <div class='text-danger' v-if="!$v.formContainerData.DangerousLabel2.minLength">{{$t('label.min')+$v.formContainerData.DangerousLabel2.$params.minLength.min+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.DangerousLabel2.maxLength">{{$t('label.max')+$v.formContainerData.DangerousLabel2.$params.maxLength.max+$t('label.chars')}} </div>
                                                    </template>
                                                </CInput>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.DangerousLabel3,'DangerousLabel3')"
                                                    :color="alertType($v.formContainerData.DangerousLabel3,'DangerousLabel3')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.DangerousLabel3,'DangerousLabel3') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.DangerousLabel3,'DangerousLabel3')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    
                                                    label="DANGEROUS GOOD LABEL 3"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    v-model="$v.formContainerData.DangerousLabel3.$model"
                                                    :addInputClasses="alertasInputs($v.formContainerData.DangerousLabel3,'DangerousLabel3')"
                                                    @focus="$v.formContainerData.DangerousLabel3.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="$v.formContainerData.DangerousLabel3.$error">
                                                        <div class='text-danger' v-if="!$v.formContainerData.DangerousLabel3.minLength">{{$t('label.min')+$v.formContainerData.DangerousLabel3.$params.minLength.min+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.DangerousLabel3.maxLength">{{$t('label.max')+$v.formContainerData.DangerousLabel3.$params.maxLength.max+$t('label.chars')}} </div>
                                                    </template>
                                                </CInput>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.DangerousLabelOptional,'DangerousLabelOptional')"
                                                    :color="alertType($v.formContainerData.DangerousLabelOptional,'DangerousLabelOptional')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.DangerousLabelOptional,'DangerousLabelOptional') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.DangerousLabelOptional,'DangerousLabelOptional')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    
                                                    label="DANGEROUS LABEL OPTIONAL"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    v-model="$v.formContainerData.DangerousLabelOptional.$model"
                                                    :addInputClasses="alertasInputs($v.formContainerData.DangerousLabelOptional,'DangerousLabelOptional')"
                                                    @focus="$v.formContainerData.DangerousLabelOptional.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="$v.formContainerData.DangerousLabelOptional.$error">
                                                        <div class='text-danger' v-if="!$v.formContainerData.DangerousLabelOptional.minLength">{{$t('label.min')+$v.formContainerData.DangerousLabelOptional.$params.minLength.min+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.DangerousLabelOptional.maxLength">{{$t('label.max')+$v.formContainerData.DangerousLabelOptional.$params.maxLength.max+$t('label.chars')}} </div>
                                                    </template>
                                                </CInput>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.DescriptionOfGoodsDGS,'DescriptionOfGoodsDGS')"
                                                    :color="alertType($v.formContainerData.DescriptionOfGoodsDGS,'DescriptionOfGoodsDGS')"
                                                    class="toatsAlert"
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.DescriptionOfGoodsDGS,'DescriptionOfGoodsDGS') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.DescriptionOfGoodsDGS,'DescriptionOfGoodsDGS')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    
                                                    label="DANGEROUS GOOD ADDITIONAL INFORMATION´S "
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    v-model="$v.formContainerData.DescriptionOfGoodsDGS.$model"
                                                    :addInputClasses="alertasInputs($v.formContainerData.DescriptionOfGoodsDGS,'DescriptionOfGoodsDGS')"
                                                    @focus="$v.formContainerData.DescriptionOfGoodsDGS.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="$v.formContainerData.DescriptionOfGoodsDGS.$error">
                                                        <div class='text-danger' v-if="!$v.formContainerData.DescriptionOfGoodsDGS.minLength">{{$t('label.min')+$v.formContainerData.DescriptionOfGoodsDGS.$params.minLength.min+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.DescriptionOfGoodsDGS.maxLength">{{$t('label.max')+$v.formContainerData.DescriptionOfGoodsDGS.$params.maxLength.max+$t('label.chars')}} </div>
                                                    </template>
                                                </CInput>
                                            </CCol>
                                        </CRow>
                                    </CCardBody>
                                </CCard>
                            </CCol>
                            <CCol sm="12">
                                <CCard class="content-seal">
                                    <CCardTitle tag="h6">
                                        <div class="title-card seal-title">
                                            <CIcon name="cil-tags"/>&nbsp;SEAL INFO
                                        </div>
                                    </CCardTitle>
                                    <CCardBody>
                                        <CRow>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.Seal1,'Seal1')"
                                                    :color="alertType($v.formContainerData.Seal1,'Seal1')"
                                                    class="toatsAlert"
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.Seal1,'Seal1') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.Seal1,'Seal1')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    
                                                    label="SEAL 1"
                                                    :addLabelClasses="obligationResinto ? 'required' : ''"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    v-model="$v.formContainerData.Seal1.$model"
                                                    :addInputClasses="alertasInputs($v.formContainerData.Seal1,'Seal1')"
                                                    @focus="$v.formContainerData.Seal1.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="$v.formContainerData.Seal1.$error">
                                                        <div class='text-danger' v-if="obligationResinto && !$v.formContainerData.Seal1.required">{{$t('label.required')}}</div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.Seal1.minLength">{{$t('label.min')+$v.formContainerData.Seal1.$params.minLength.min+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.Seal1.maxLength">{{$t('label.max')+$v.formContainerData.Seal1.$params.maxLength.max+$t('label.chars')}} </div>
                                                    </template>
                                                </CInput>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.Seal2,'Seal2')"
                                                    :color="alertType($v.formContainerData.Seal2,'Seal2')"
                                                    class="toatsAlert"
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.Seal2,'Seal2') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.Seal2,'Seal2')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    
                                                    label="SEAL 2"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    v-model="$v.formContainerData.Seal2.$model"
                                                    :addInputClasses="alertasInputs($v.formContainerData.Seal2,'Seal2')"
                                                    @focus="$v.formContainerData.Seal2.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="$v.formContainerData.Seal2.$error">
                                                        <div class='text-danger' v-if="!$v.formContainerData.Seal2.minLength">{{$t('label.min')+$v.formContainerData.Seal2.$params.minLength.min+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.Seal2.maxLength">{{$t('label.max')+$v.formContainerData.Seal2.$params.maxLength.max+$t('label.chars')}} </div>
                                                    </template>
                                                </CInput>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.Seal3,'Seal3')"
                                                    :color="alertType($v.formContainerData.Seal3,'Seal3')"
                                                    class="toatsAlert"
                                                    
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.Seal3,'Seal3') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.Seal3,'Seal3')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    
                                                    label="SEAL 3"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    v-model="$v.formContainerData.Seal3.$model"
                                                    :addInputClasses="alertasInputs($v.formContainerData.Seal3,'Seal3')"
                                                    @focus="$v.formContainerData.Seal3.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="$v.formContainerData.Seal3.$error">
                                                        <div class='text-danger' v-if="!$v.formContainerData.Seal3.minLength">{{$t('label.min')+$v.formContainerData.Seal3.$params.minLength.min+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.Seal3.maxLength">{{$t('label.max')+$v.formContainerData.Seal3.$params.maxLength.max+$t('label.chars')}} </div>
                                                    </template>
                                                </CInput>
                                            </CCol>
                                            <CCol sm="12">
                                                <CAlert 
                                                    :show="alertContent($v.formContainerData.Seal4,'Seal4')"
                                                    :color="alertType($v.formContainerData.Seal4,'Seal4')"
                                                    class="toatsAlert"
                                                >
                                                    <div class="d-flex bd-highlight align-items-center">
                                                        <div class="p-2 bd-highlight">
                                                            <CIcon class="icon-toast" :name="alertType($v.formContainerData.Seal4,'Seal4') === 'danger' ? 'cil-exclamation-circle' : 'cil-warning'"/>
                                                        </div> 
                                                        <div class="p-2 bd-highlight flex-grow-1">
                                                            <b>&nbsp;{{alertMessage($v.formContainerData.Seal4,'Seal4')}}</b>
                                                        </div>
                                                    </div>
                                                </CAlert>
                                                <CInput
                                                    size="sm"
                                                    
                                                    label="SEAL 4"
                                                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                                    v-model="$v.formContainerData.Seal4.$model"
                                                    :addInputClasses="alertasInputs($v.formContainerData.Seal4,'Seal4')"
                                                    @focus="$v.formContainerData.Seal4.$touch()"
                                                >
                                                    <template #invalid-feedback v-if="$v.formContainerData.Seal4.$error">
                                                        <div class='text-danger' v-if="!$v.formContainerData.Seal4.minLength">{{$t('label.min')+$v.formContainerData.Seal4.$params.minLength.min+$t('label.chars')}} </div>
                                                        <div class='text-danger' v-if="!$v.formContainerData.Seal4.maxLength">{{$t('label.max')+$v.formContainerData.Seal4.$params.maxLength.max+$t('label.chars')}} </div>
                                                    </template>
                                                </CInput>
                                            </CCol>
                                        </CRow>
                                    </CCardBody>
                                </CCard>
                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>
            </div>
            <div slot="footer">
                <CButton
                    size="sm"
                    color="success"
                    @click="onBtnExport()"
                    class="m-2"
                    :disabled="apiStateLoading"
                >
                    <CIcon name="cil-file-excel"/>&nbsp;
                    XLSX
                </CButton>

                <CButton
                    size="sm"
                    color="add"
                    class="m-2"
                    @click="registerData"
                    :disabled="apiStateLoading"
                >
                    <div v-if="apiStateLoading">
                        <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                        {{$t('button.accept')}}
                    </div>

                    <div v-if="!apiStateLoading">
                        <CIcon name="checkAlt"/>&nbsp;
                        {{$t('button.accept')}}
                    </div>
                </CButton>
                <CButton
                    size="sm"
                    color="wipe"
                    @click="cerrarModal"
                    class="m-2"
                    :disabled="apiStateLoading"
                >
                <CIcon name="x"/>&nbsp;
                    {{$t('button.cancel')}}
                </CButton>
            </div>
        </CModal>
    </div>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import  { FormContainerData }  from '@/_validations/planificacion-estiba/containerdataValidations';
    import { VMoney,Money } from 'v-money';
    import DataStorage from '@/_helpers/localStorage';
    import Excel from "exceljs/dist/es5/exceljs.browser";
    import { saveAs } from "file-saver";
    import { DateFormater } from '@/_helpers/funciones';

    //data
    function data() {
        return {
            tituloModal: 'CONTAINER DATA',
            classAll:[],
            bayAll:[],
            cargoStatusAll:[],
            portAll:[],
            deliveryPortAll:[],
            serviceLineAll:[],
            headingAll:[],
            hazardCodeAll:[],
            hazardClassAll:[],
            packagingGroupAll:[],
            slotAll:[],
            refeerAll:[],
            commodityAll:[],
            containerAll:[],
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '',
                precision: 2,
                masked: false
            },
            AlertJson:[],
            ErrorJson:[],
            formContainerData:{
                ContainerCode:'',
                VesselBayId:null,
                BayCode:0,
                VesselBayPosId:null,
                BaySlot:0,
                SizeId:null,
                Size:'',
                TpCargoClassId:null,
                Class:'',
                TpCargoStatusId:null,
                Status:'',
                LoadPortId:null,
                LoadPort:'',
                DischargePortId:null,
                DischargePort:'',
                DeliveryPortId:null,
                DeliveryPort:'',
                OptionalPortId:null,
                OptionalPort:'',
                TranshipmentPortId:null,
                TranshipmentPort:'',
                OriginalPortLoading:'',
                OriginalPortLoadingId:null,
                ShippingLineId:null,
                ShippingLineCode:'',
                ShippingLineName:'',
                HeadingId:null,
                CommodityId:null,
                CommodityCode:'',
                CommodityIdNumber:'',
                HandlingRemark:'',
                ContainerRemark:'',
                SpecialInstruction:'',
                GeneralInfo:'',
                DescriptionOfGoods:'',
                BookingNumber:'',
                Consignee:'',
                ShipperOwn:'',
                Shipper:'',
                BlNo:'',
                Dua:'',
                //columna 2
                IsoCode:'',
                TpCargoId:null,
                MetadataId:null,
                Lenght:'0,00',
                UnitMeasureLenght:null,
                Height:'0,00',
                UnitMeasureHeigth:null,
                TpCargoName:'',
                TpCargoCode:'',
                Height:'0,00',
                UnitMeasureHeigth:null,
                Weigth:'0,00',
                UnitMeasureWeigth:null,
                Vgm:'0,00',
                UnitMeasureVgm:null,
                DescriptionType:'',
                //columna 3
                UnitMeasureRf:null,
                ReeferSetting:'',
                MinimumRangeRf:'',
                MaximumRangeRf:'',
                PluggedIn:true,
                //columna 4
                UnitMeasureDim:null,
                DimFront:0,
                DimBack:0,
                DimLeft:0,
                DimRight:0,
                DimTop:0,
                //columna 5
                ImdgClassId:null,
                ImdgId:null,
                ImdgCode:'',
                ImdgClassCode:'',
                ImgdPageNo:'',
                UnNumber:'',
                FlashPoint:'',
                UnitMeasureFlashPoint:null,
                PackagingGroupId:null,
                PackagingGroupCode:'',
                EmergencySheduleNo:'',
                MedicalGuide:'',
                ImdgCodePlaceCardUpper:'',
                ImdgCodePlaceCardLower:'',
                DangerousLabel1:'',
                DangerousLabel2:'',
                DangerousLabel3:'',
                DangerousLabelOptional:'',
                DescriptionOfGoodsDGS:'',
                Seal1:'',
                Seal2:'',
                Seal3:'',
                Seal4:'',
            }
        }
    }

    //methods
    function maxLength(e,max){
        if(e.target.value !== '' && e.target.value !== null){
            if(e.target.value.length >= max){
                e.preventDefault();
                return false;
            }else{
                return true;
            }
        }
        return true;
    }
    async function onBtnExport(){
        this.$v.formContainerData.$touch();
        if (this.$v.formContainerData.$pending || this.$v.formContainerData.$error) {
            this.$notify({
                group: 'container',
                text: 'Campos incorrectos, vuelva a ingresarlos.',
                type: "error"
            });
            return
        };
        try{
            this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;

            let titleWEIGTH = 'WEIGTH';
            if(this.formContainerData.UnitMeasureWeigth !== null && this.formContainerData.UnitMeasureWeigth !== ''){
                const indexWeigth = this.dataWeight.map(function(e) {
                    return e.UnitMeasureId; 
                }).indexOf(this.formContainerData.UnitMeasureWeigth);
                if(indexWeigth !== -1){
                    titleWEIGTH = `WEIGTH (${this.dataWeight[indexWeigth].UnitMeasureAbbrev})`;
                }
            }

            let titleVGM = 'VGM';
            if(this.formContainerData.UnitMeasureVgm !== null && this.formContainerData.UnitMeasureVgm !== ''){
                const indexVgm = this.dataWeight.map(function(e) {
                    return e.UnitMeasureId; 
                }).indexOf(this.formContainerData.UnitMeasureVgm);
                if(indexVgm !== -1){
                    titleVGM = `VGM (${this.dataWeight[indexVgm].UnitMeasureAbbrev})`;
                }
            }

            let titleHEIGHT = 'HEIGHT';
            if(this.formContainerData.UnitMeasureHeigth !== null && this.formContainerData.UnitMeasureHeigth !== ''){
                const indexHEIGHT = this.dataLongitud.map(function(e) {
                    return e.UnitMeasureId; 
                }).indexOf(this.formContainerData.UnitMeasureHeigth);
                if(indexHEIGHT !== -1){
                    titleHEIGHT = `HEIGHT (${this.dataLongitud[indexHEIGHT].UnitMeasureAbbrev})`;
                }
            }

            let titleLENGHT = 'LENGHT';
            if(this.formContainerData.UnitMeasureLenght !== null && this.formContainerData.UnitMeasureLenght !== ''){
                const indexLENGHT = this.dataLongitud.map(function(e) {
                    return e.UnitMeasureId; 
                }).indexOf(this.formContainerData.UnitMeasureLenght);
                if(indexLENGHT !== -1){
                    titleLENGHT = `LENGHT (${this.dataLongitud[indexLENGHT].UnitMeasureAbbrev})`;
                }
            }

            let headingValue = '';
        
            if(this.formContainerData.HeadingId !== '' && this.formContainerData.HeadingId !== null){
                const indexHeading = this.headingAll.map(async (e) =>{
                    return e.HeadingId; 
                }).indexOf(this.formContainerData.HeadingId);
                if(indexHeading !== -1){
                    headingValue = this.headingAll[indexHeading].HeadingName;
                }
            }
            
            let commodityValue = '';
            
            if(this.formContainerData.CommodityId !== '' && this.formContainerData.CommodityId !== null){
                const indexCommodity = this.commodityAll.map(function(e) {
                    return e.CommodityId; 
                }).indexOf(this.formContainerData.CommodityId);
                if(indexCommodity !== -1){
                    commodityValue = this.commodityAll[indexCommodity].CommodityName;
                }
            }

            let rowData = [
                {
                    headerName:'#',
                    value:"1"
                },
                {
                    headerName:'BAY',
                    value:this.formContainerData.BayCode
                },
                {
                    headerName:'SLOT',
                    value:this.formContainerData.BaySlot
                },
                {
                    headerName:'CONTAINER',
                    value:this.formContainerData.ContainerCode,
                },
                {
                    headerName:'SIZE FT',
                    value:this.formContainerData.Size,
                },
                {
                    headerName:'ISO CODE',
                    value:this.formContainerData.IsoCode,
                },
                {
                    headerName:"SEAL 1",
                    value:this.formContainerData.Seal1,
                },
                {
                    headerName:"SEAL 2",
                    value:this.formContainerData.Seal2,
                },
                {
                    headerName:"SEAL 3",
                    value:this.formContainerData.Seal3,
                },
                {
                    headerName:"SEAL 4",
                    value:this.formContainerData.Seal4,
                },
                {
                    headerName:'STATUS',
                    value:this.formContainerData.Status,
                },
                {
                    headerName:'CARRIER CODE',
                    value:this.formContainerData.ShippingLineCode,
                },
                {
                    headerName:'CARRIER',
                    value:this.formContainerData.ShippingLineName,
                },
                {
                    headerName:'IMDG CODE',
                    value:this.formContainerData.ImdgCode,
                },
                {
                    headerName:'LOAD PORT',
                    value:this.formContainerData.LoadPort,
                },
                {
                    headerName:'DISCHARGE PORT',
                    value:this.formContainerData.DischargePort,
                },
                {
                    headerName:'DELIVERY PORT',
                    value:this.formContainerData.DeliveryPort,
                },
                {
                    headerName:titleWEIGTH,
                    value:this.formContainerData.Weigth,
                },
                {
                    headerName:titleHEIGHT,
                    value:this.formContainerData.Height,
                },
                {
                    headerName:'OPTIONAL PORT',
                    value:this.formContainerData.OptionalPort,
                },
                {
                    headerName:'ORIGINAL PORT OF LOADING',
                    value:this.formContainerData.OriginalPortLoading,
                },
                {
                    headerName:'TRANSSHIPMENT PORT',
                    value:this.formContainerData.TranshipmentPort,
                },
                {
                    headerName:'HEADING',
                    value:headingValue,
                },
                {
                    headerName:'COMMODITY',
                    value:commodityValue,
                },
                {
                    headerName:'COMMODITY CODE',
                    value:this.formContainerData.CommodityCode,
                },
                {
                    headerName:'COMMODITY ID NUMBER',
                    value:this.formContainerData.CommodityIdNumber,
                },
                {
                    headerName:'HANDLING REMARK',
                    value:this.formContainerData.HandlingRemark,
                },
                {
                    headerName:'CONTAINER REMARK',
                    value:this.formContainerData.ContainerRemark,
                },
                {
                    headerName:'SPECIAL INSTRUCTION',
                    value:this.formContainerData.SpecialInstruction,
                },
                {
                    headerName:'GENERAL INFO',
                    value:this.formContainerData.GeneralInfo,
                },
                {
                    headerName:'DESCRIPTION OF GOODS',
                    value:this.formContainerData.DescriptionOfGoods,
                },
                {
                    headerName:'BOOKING NUMBER',
                    value:this.formContainerData.BookingNumber,
                },
                {
                    headerName:'CONSIGNEE',
                    value:this.formContainerData.Consignee,
                },
                {
                    headerName:'SHIPPER OWN',
                    value:this.formContainerData.ShipperOwn,
                },
                {
                    headerName:'SHIPPER',
                    value:this.formContainerData.Shipper,
                },
                {
                    headerName:'BL No',
                    value:this.formContainerData.BlNo,
                },
                {
                    headerName:'DUA',
                    value:this.formContainerData.Dua,
                },
                {
                    headerName:titleLENGHT,
                    value:this.formContainerData.Lenght,
                },
                {
                    headerName:'GROUP TYPE',
                    value:this.formContainerData.TpCargoName,
                },
                {
                    headerName:'TYPE CARGO CODE',
                    value:this.formContainerData.TpCargoCode,
                },
                {
                    headerName:titleVGM,
                    value:this.formContainerData.Vgm,
                },
                {
                    headerName:'DESCRIPTION',
                    value:this.formContainerData.DescriptionType,
                },
                {
                    headerName:'REEFER SETTING',
                    value:this.formContainerData.ReeferSetting,
                },
                {
                    headerName:'DRY REEFER',
                    value:this.formContainerData.PluggedIn ? 'SI' : 'NO',
                },
                {
                    headerName:'MINIMUM RANGE RF',
                    value:this.formContainerData.MinimumRangeRf,
                },
                {
                    headerName:'MAXIMUM RANGE RF',
                    value:this.formContainerData.MaximumRangeRf,
                },
                {
                    headerName:'DIM FRONT',
                    value:this.formContainerData.DimFront,
                },
                {
                    headerName:'DIM BACK',
                    value:this.formContainerData.DimBack,
                },
                {
                    headerName:'DIM LEFT',
                    value:this.formContainerData.DimLeft,
                },
                {
                    headerName:'DIM RIGHT',
                    value:this.formContainerData.DimRight,
                },
                {
                    headerName:'DIM TOP',
                    value:this.formContainerData.DimTop,
                },
                {
                    headerName:'HAZARD CLASS',
                    value:this.formContainerData.ImdgClassCode,
                },
                {
                    headerName:'IMDG PAGE NO',
                    value:this.formContainerData.ImgdPageNo,
                },
                {
                    headerName:'UN NUMBER',
                    value:this.formContainerData.UnNumber,
                },
                {
                    headerName:'FLASH POINT',
                    value:this.formContainerData.FlashPoint,
                },
                {
                    headerName:'PACKAGING GROUP',
                    value:this.formContainerData.PackagingGroupCode,
                },
                {
                    headerName:'EMERGENCY SCHEDULE NO',
                    value:this.formContainerData.EmergencySheduleNo,
                },
                {
                    headerName:'MEDICAL FIRST AID GUIDE NO',
                    value:this.formContainerData.MedicalGuide,
                },
                {
                    headerName:'ORANGE HAZARD PLACE CARD UPPER PART IDENTIFIER',
                    value:this.formContainerData.ImdgCodePlaceCardUpper,
                },
                {
                    headerName:'ORANGE HAZARD PLACE CARD LOWER PART IDENTIFIER',
                    value:this.formContainerData.ImdgCodePlaceCardLower,
                },
                {
                    headerName:'DANGEROUS GOOD LABEL 1',
                    value:this.formContainerData.DangerousLabel1,
                },
                {
                    headerName:'DANGEROUS GOOD LABEL 2',
                    value:this.formContainerData.DangerousLabel2,
                },
                {
                    headerName:'DANGEROUS GOOD LABEL 3',
                    value:this.formContainerData.DangerousLabel3,
                },
                {
                    headerName:'DANGEROUS GOOD LABEL 3',
                    value:this.formContainerData.DangerousLabel3,
                },
                {
                    headerName:"DANGEROUS LABEL OPTIONAL",
                    value:this.formContainerData.DangerousLabelOptional,
                },
                {
                    headerName:"DANGEROUS GOOD ADDITIONAL INFORMATION'S",
                    value:this.formContainerData.DescriptionOfGoodsDGS,
                },
                
            ];
            let DatePlanning = '';
            let CountryName = '';
            let VesselFlagRoute = '';

            const workbook = new Excel.Workbook();
            const worksheet = workbook.addWorksheet("ContainerList");

            const Index = this.myDataItinirary.map(function(e) {
                return e.ItineraryId; 
            }).indexOf(this.ItineraryId);

            if(Index > -1){      
                 DatePlanning = this.myDataItinirary[Index].VesselName+" "+this.myDataItinirary[Index].Imo+", "+this.myDataItinirary[Index].CountryName+", ETA: "+DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Eta)+", ETD: "+DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Etd)+" - "+this.$t('label.voyage')+": "+this.myDataItinirary[Index].Voyage;
                 CountryName = this.myDataItinirary[Index].CountryName;
            } else {
                let itinerarySelected = this.itinerarySelected;
                DatePlanning = `${itinerarySelected.VesselName}, - ${this.$t('label.voyage')}: ${itinerarySelected.Voyage}, ${itinerarySelected.LabelArrival} ${DateFormater.formatDateTimeWithSlash(itinerarySelected.Arrival)} - ${itinerarySelected.LabelDeparture} ${DateFormater.formatDateTimeWithSlash(itinerarySelected.Departure)}`;
                 CountryName = this.itinerarySelected.CountryName;
                 VesselFlagRoute = this.itinerarySelected.VesselFlagRoute;
            }
            worksheet.mergeCells('A1:B2');
            const response1 = await this.$http.getFile('base64', {
                path:this.user.LogoRoute.replace('Public/','', null, 'i')
            });
            const divisiones1 = this.user.LogoRoute.split(".");
            const myBase64Image = 'data:image/'+divisiones1[1]+';base64,'+response1.data.data.base64;
            const imageId = workbook.addImage({
                base64: myBase64Image,
                extension: divisiones1[1],
            });
            worksheet.addImage(imageId, 'A1:B2');
            worksheet.getCell("A1").border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            const rowIndex1 = worksheet.getRow(1);
            rowIndex1.height = 100;

            worksheet.mergeCells("C1:BN1");
            worksheet.mergeCells("C2:BN2");

            const customCellCompanyBranch = worksheet.getCell("C1");
            const row1 = worksheet.getRow(1);
            row1.height = 30;
            customCellCompanyBranch.font = {
                name: "Calibri",
                family: 4,
                size: 11,
                underline: false,
                bold: true
            };
            customCellCompanyBranch.note = {
                margins : {
                    insetmode: 'auto',
                    inset: [10, 10, 5, 5]
                }
            }
            customCellCompanyBranch.fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{
                    argb:'f2f2f2' 
                }
            }
            customCellCompanyBranch.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
            customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
           
           //COLUMNA 2
            const row2 = worksheet.getRow(2);    
            row2.height = 30;
            const customCell = worksheet.getCell("C2");
            customCell.font = {
                name: "Calibri",
                family: 4,
                size: 11,
                underline: false,
                bold: true
            };
            customCell.fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{
                    argb:'f2f2f2'
                }
            }

            customCell.border = {
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

            customCell.value =  DatePlanning;
            customCell.alignment = { vertical: 'middle', horizontal: 'center' }

            //titulo
            worksheet.getRow(3).height = 30;
            worksheet.mergeCells("A3:BN3");
            const customCell2 = worksheet.getCell("A3");
            customCell2.font = {
                name: "Calibri",
                family: 4,
                size: 12,
                underline: false,
                bold: true
            };
            customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            customCell2.value = 'CONTAINER DATA';
            customCell2.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

            //impresion
            worksheet.mergeCells("A4:AN4");
            const customCell3 = worksheet.getCell("A4");
            customCell3.height = 50;
            customCell3.font = {
                name: "Calibri",
                family: 4,
                size: 12,
                underline: false,
                bold: true
            };
            const hoy = new Date();
            customCell3.value = this.$t('label.printed')+": "+hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
            customCell3.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCell3.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
            //usuario
            worksheet.mergeCells("AO4:BN4");
            const customCell4 = worksheet.getCell("AO4");
            customCell4.height = 50;
            customCell4.font = {
                name: "Calibri",
                family: 4,
                size: 12,
                underline: false,
                bold: true
            };
            customCell4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
            customCell4.value = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
            customCell4.border = {
                top: {style:'thin'},
                right: {style:'thin'},
                bottom: {style:'thin'},
            }


            //linea
        worksheet.mergeCells("A5:BN5");
        const customCell8 = worksheet.getCell("A5");
        customCell8.height = 50;
        customCell8.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCell8.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            var headerRow = worksheet.addRow();
            headerRow.font =  {
                name: "Calibri",
                family: 4,
                size: 11,
                underline: false,
                bold: true
            };
         
            headerRow.height = 30;
            let i = 0;
            rowData.map(async (data) => {
                let width = data.headerName ? data.headerName.toString().length : 10;
                worksheet.getColumn(i + 1).width = width < 10 ? 15 : width*2;
                let cell = headerRow.getCell(i + 1);
                cell.value = data.headerName;
                cell.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'cccccc'
                    }
                }
                cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                i++;
            })

            let dataRow = worksheet.addRow();
            let indice = 0;
            rowData.map(async (data) => {
                let cell = dataRow.getCell(indice + 1);
                cell.value = data.value;
                cell.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                };
                cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                indice++;
            })
            
            workbook.xlsx.writeBuffer()
            .then(buffer => {
                this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                saveAs(new Blob([buffer]), `${Date.now()}_lista_contenedores.xlsx`)
                this.$notify({
                    group: 'container',
                    text: 'Formato Generado!',
                    type: "success"
                });
            })
            .catch(err => {
                this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
            })
        } catch (error) {
            this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
            this.$store.commit('planificacionestiba/messageMutation', error);
        }

        

    }
    function alertasInputs(field,fieldName){
        if(field.$anyDirty){
            return field.$error ? 'is-invalid' : '';
        }else{
            let banderaAlert = false;
            this.AlertJson.map(async (data) => {
                if(data.ColumnName === fieldName){
                    banderaAlert = true;
                }
            })
            let banderaError = false;
            this.ErrorJson.map(async (data) => {
                if(data.ColumnName === fieldName){
                    banderaError = true;
                }
            })
            return banderaError ? 'is-invalid' : (banderaAlert ? 'is-warning' : '');
        }
    }
    function alertContent(field,fieldName){
        if(field.$anyDirty || field === undefined){
            return false;
        }else{
            let banderaAlert = false;
            
            this.ErrorJson.map(async (data) => {
                if(data.ColumnName === fieldName){
                    banderaAlert = true;
                }
            })
            if(!banderaAlert){
                this.AlertJson.map(async (data) => {
                    if(data.ColumnName === fieldName){
                        banderaAlert = true;
                    }
                })
            }
            return banderaAlert;
        }
    }
    function alertType(field,fieldName){
        if(field.$anyDirty){
            return 'primary';
        }else{
            let banderaAlert = false;
            this.AlertJson.map(async (data) => {
                if(data.ColumnName === fieldName){
                    banderaAlert = true;
                }
            })
            let banderaError = false;
            this.ErrorJson.map(async (data) => {
                if(data.ColumnName === fieldName){
                    banderaError = true;
                }
            })
            return banderaError ? 'danger' : (banderaAlert ? 'warning' : 'primary');
        }
    }
    function alertMessage(field,fieldName){
        if(field.$anyDirty){
            return '';
        }else{
            let message = '';
            let local = DataStorage.getLocale();
            this.AlertJson.map(async (data) => {
                if(data.ColumnName == fieldName){
                    message = local == 'es'? data.EdiMessageSpanish : data.EdiMessageEnglish;
                }
            })
            this.ErrorJson.map(async (data) => {
                if(data.ColumnName == fieldName){
                    message = local == 'es'? data.EdiMessageSpanish : data.EdiMessageEnglish;
                }
            })
            return message;
        }
    }

    function conversiones(newValue,OldValue,Value,tipo){
        try{
            let conversion = parseFloat(Value.replace(/[.]/g, '').replace(',', '.')).toFixed(2);
            let retornado = 0;
            this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
            this.$http.ejecutar('GET', 'UnitMeasureConversion', 
                { 
                    UnitMeasureToId:newValue,
                    UnitMeasureFromId:OldValue, 
                    Value:conversion 
                }, '').then(response => {
                if(response.status === 200){
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                    
                    const i = parseFloat(response.data.data[0].Value).toFixed(2);
                    if(tipo === 1){
                        this.formContainerData.Weigth = i;
                        const input = this.$refs.weigthInput.$el.querySelector("input");
                        input.value = i;
                        var inputNombre = document.getElementById("weigthInput");
                        inputNombre.value = i;
                    }else if(tipo === 2){
                        this.formContainerData.Vgm = i;
                        const input = this.$refs.vgmInput.$el.querySelector("input");
                        input.value = i;
                        var inputNombre = document.getElementById("vgmInput");
                        inputNombre.value = i;
                    }else if(tipo === 3){
                        this.formContainerData.Lenght = i;
                        const input = this.$refs.lenghtInput.$el.querySelector("input");
                        input.value = i;
                        var inputNombre = document.getElementById("lenghtInput");
                        inputNombre.value = i;
                    }else if(tipo === 4){
                        this.formContainerData.Height = i;
                        const input = this.$refs.heightInput.$el.querySelector("input");
                        input.value = i;
                        var inputNombre = document.getElementById("heightInput");
                        inputNombre.value = i;
                    }
                }else{
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                }
            }).catch(err => {
                this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                this.$store.commit('maquina/messageMutation', err);
            })
        } catch (error) {
            this.$store.state.planificacionestiba.apiState = ENUM.ERROR;
            this.$store.commit('planificacionestiba/messageMutation', error);
            return 0;
        }
    }
    function cerrarModal(){
        if(this.apiStateLoading)
            return
        this.$store.state.planificacionestiba.modalContainerData = false;
    }
    async function cargarOptional(){
        this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
        if(this.formContainerData.Size !== null && this.formContainerData.Size !== '' && this.formContainerData.Size !== 0){
            await this.$http.ejecutar('GET', 'StowagePlanningFreeVesselBayPos-by-Size', { StowagePlanningId:this.planificacionId,EdiFileId:this.EdiFileId, Size:this.formContainerData.Size }, '').then(responseBay => {
                if(responseBay.status === 200){
                    this.bayAll = responseBay.data.data;

                    if(this.formContainerData.VesselBayId !== null && this.formContainerData.VesselBayId !== ''){
                        const indexBay = this.bayAll.map(function(e) {
                            return e.VesselBayId; 
                        }).indexOf(this.formContainerData.VesselBayId);
                        if(indexBay !== -1){
                            this.slotAll = this.bayAll[indexBay].PositionJson;
                            this.formContainerData.VesselBayPosId = this.myData.VesselBayPosId;
                        }
                        this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                    }else{
                        this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                    }
                }else{
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                }
            }).catch(err => {
                this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                this.$store.commit('maquina/messageMutation', err);
            });
        }
        if(this.formContainerData.HeadingId !== null && this.formContainerData.HeadingId !== ''){
            await this.$http.ejecutar('GET', 'Commodity-list', { Filter: 'ACTIVO', HeadingId:this.formContainerData.HeadingId }, '').then(responseCommodity => {
                if(responseCommodity.status === 200){
                    this.commodityAll = responseCommodity.data.data;
                }else{
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                }
            }).catch(err => {
                this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                this.$store.commit('maquina/messageMutation', err);
            })
        }
        if(this.formContainerData.ImdgClassId !== null && this.formContainerData.ImdgClassId !== ''){
            await this.$http.ejecutar('GET', 'Imdg-list', { ImdgClassId:this.formContainerData.ImdgClassId,filter:'ACTIVO' }, '').then(responseHazardClassList => {
                if(responseHazardClassList.status === 200){
                    this.hazardClassAll = responseHazardClassList.data.data;
                }else{
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                }
            }).catch(err => {
                this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                this.$store.commit('maquina/messageMutation', err);
            })
        }
        this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
    }
    function registerData(){
        this.$v.formContainerData.$touch();
        if (this.$v.formContainerData.$pending || this.$v.formContainerData.$error) {
            this.$notify({
                group: 'container',
                text: 'Campos incorrectos, vuelva a ingresarlos.',
                type: "error"
            });
            return
        };
        const weigth = parseFloat(this.formContainerData.Weigth.replace(/[.]/g, '').replace(',', '.')).toFixed(2);
        if(weigth !== '' && weigth === null && this.limitWEIGHT !== 0.00){
            if(weigth > this.limitWEIGHT){
                const mesage = this.$t('label.container_data.container_weight')+this.numberTotalWEIGHT;
                this.$notify({
                    group: 'container',
                    text: mesage,
                    type: "error"
                });
                return
            }
        }
        const vgm = parseFloat(this.formContainerData.Vgm.replace(/[.]/g, '').replace(',', '.')).toFixed(2);
        if(this.obligationVGM){
            if(vgm !== '' && vgm === null && this.limitVGM !== 0.00){
                if(vgm > this.limitVGM){
                    const mesage2 = $t('label.container_data.container_size')+numberTotalVGM;
                    this.$notify({
                        group: 'container',
                        text: mesage2,
                        type: "error"
                    });
                    return
                }
            }
        }


        let StowagePlanningCargoJson = {
            StowagePlanningCargoId:this.myData.StowagePlanningCargoId, // Id de Control en Lista de Carga Obligatorio, 
            ContainerCode:this.formContainerData.ContainerCode, // Siglas del contenedor Obligatorio, 
            MetadataId:this.formContainerData.MetadataId, // Id de Control de la metadada (Siglas vs documento internacional) Obligatorio, 
            VesselBayId:this.formContainerData.VesselBayId,
            VesselBayPosId:this.formContainerData.VesselBayPosId,// Posicion de localizacion del equipo en el buque Obligatorio 
            TpCargoClassId:this.formContainerData.TpCargoClassId,// Clase del Contenedor segun su nivel standar 
            TpCargoStatusId:this.formContainerData.TpCargoStatusId,// Status de un contenedor en relacion al almacenaje o no de la carga Obligatorio 
            LoadPortId:this.formContainerData.LoadPortId,// Puerto de Carga, Obligatorio 
            DischargePortId:this.formContainerData.DischargePortId,// Puerto de Descarga, Obligatorio 
            DeliveryPortId:this.formContainerData.DeliveryPortId,
            OptionalPortId:this.formContainerData.OptionalPortId,
            //SizeId:this.formContainerData.SizeId,
            OriginalPortLoadingId:this.formContainerData.OriginalPortLoadingId,
            TranshipmentPortId:this.formContainerData.TranshipmentPortId,
            ShippingLineId:this.formContainerData.ShippingLineId,//Id Linea Naviera Obligatorio 
            HeadingId:this.formContainerData.HeadingId,// Id del Rubro de la Carga 
            CommodityId:this.formContainerData.CommodityId, // Id de la mercancia 
            ImdgClassId:this.formContainerData.ImdgClassId,// Id de la Clase de carga peligrosa 
            ImdgId:this.formContainerData.ImdgId,// Id de Imdg 
            CommodityCode:this.formContainerData.CommodityCode, // Codigo Internacional de identificacion de la mercancia 
            CommodityIdNumber:this.formContainerData.CommodityIdNumber,// Numero relacionadoo a la carga peligrosa en relacion a la mercancia 
            HandlingRemark:this.formContainerData.HandlingRemark, //Observacion o instruccion de manejo del equipo 
            ContainerRemark:this.formContainerData.ContainerRemark, 
            SpecialInstruction:this.formContainerData.SpecialInstruction, // Instruccion especiales para el manejo de parte del capitan del buque normalmente 
            GeneralInfo:this.formContainerData.GeneralInfo,// Observacion general relacionada a la carga 
            DescriptionOfGoods:this.formContainerData.DescriptionOfGoods,// detalle de la mercancia 
            Lenght:parseFloat(this.formContainerData.Lenght.replace(/[.]/g, '').replace(',', '.')).toFixed(2), 
            UnitMeasureLenght:this.formContainerData.UnitMeasureLenght,// Centimetros por defecto 
            Height:parseFloat(this.formContainerData.Height.replace(/[.]/g, '').replace(',', '.')).toFixed(2),  
            UnitMeasureHeigth:this.formContainerData.UnitMeasureHeigth,// centimetros por defecto 
            Weigth:parseFloat(this.formContainerData.Weigth.replace(/[.]/g, '').replace(',', '.')).toFixed(2),
            UnitMeasureWeigth:this.formContainerData.UnitMeasureWeigth,// kilogramos por defecto 
            Vgm:parseFloat(this.formContainerData.Vgm.replace(/[.]/g, '').replace(',', '.')).toFixed(2), 
            UnitMeasureVgm:this.formContainerData.UnitMeasureVgm,// kilogramos por defecto 
            UnitMeasureRfId:this.formContainerData.UnitMeasureRf,// unidad de medida para el equipo refrigerado , en caso de serlo en estatus FULL O LCL obligatorio minimun y maximun rf 
            ReeferSetting: this.formContainerData.ReeferSetting,
            MinimumRangeRf:this.formContainerData.MinimumRangeRf, // obligatorio para reefer FULL O LCL 
            MaximumRangeRf:this.formContainerData.MaximumRangeRf,// obligatorio para reefer FULL O LCLC 
            PluggedIn:this.formContainerData.PluggedIn ? 1 : 0,
            UnitMeasureDim:this.formContainerData.UnitMeasureDim,// centimetros por defecto para baplie, obligatorio alguno de los valores, por lo menos 1, valores mayores a 0 DimFront, DimBack, DimLeft, DimRight, DimTop 
            DimFront:parseFloat(this.formContainerData.DimFront.replace(/[.]/g, '').replace(',', '.')).toFixed(2),
            DimBack:parseFloat(this.formContainerData.DimBack.replace(/[.]/g, '').replace(',', '.')).toFixed(2), 
            DimLeft:parseFloat(this.formContainerData.DimLeft.replace(/[.]/g, '').replace(',', '.')).toFixed(2),  
            DimRight:parseFloat(this.formContainerData.DimRight.replace(/[.]/g, '').replace(',', '.')).toFixed(2),  
            DimTop:parseFloat(this.formContainerData.DimTop.replace(/[.]/g, '').replace(',', '.')).toFixed(2), 
            ImgdPageNo:this.formContainerData.ImgdPageNo,// valor por defecto de la clase de imdg 
            UnNumber:this.formContainerData.UnNumber,// numero internacional para este tipo de carga peligrosa si existiera, no es obligatorio 
            FlashPoint:this.formContainerData.FlashPoint,
            UnitMeasureFlashPoint:this.formContainerData.UnitMeasureFlashPoint,// si existe flashpoint obligatorio la unidad de medida 
            PackagingGroupId:this.formContainerData.PackagingGroupId,// clasificacion de grupo de la carga peligrosa 
            EmergencySheduleNo:this.formContainerData.EmergencySheduleNo,// datos complementarios en relacion a la carga peligrosa 
            MedicalGuide:this.formContainerData.MedicalGuide,// datos complementarios en relacion a la carga peligrosa 
            ImdgCodePlaceCardUpper:this.formContainerData.ImdgCodePlaceCardUpper,// datos complementarios en relacion a la carga peligrosa 
            ImdgCodePlaceCardLower:this.formContainerData.ImdgCodePlaceCardLower,// datos complementarios en relacion a la carga peligrosa 
            DangerousLabel1:this.formContainerData.DangerousLabel1,// datos complementarios en relacion a la carga peligrosa 
            DangerousLabel2:this.formContainerData.DangerousLabel2,// datos complementarios en relacion a la carga peligrosa 
            DangerousLabel3:this.formContainerData.DangerousLabel3,// datos complementarios en relacion a la carga peligrosa 
            DangerousLabelOptional:this.formContainerData.DangerousLabelOptional,// datos complementarios en relacion a la carga peligrosa 
            DescriptionOfGoodsDGS:this.formContainerData.DescriptionOfGoodsDGS,// datos complementarios en relacion a la carga peligrosa 
            BookingNumber:this.formContainerData.BookingNumber,// nro del booking 
            Seal1:this.formContainerData.Seal1,// obligatorio al menos uno de los sellos si el equipo es full en un baplie de carga "0bdb045b-f7cf-4782-8acf-ac04a1b342cf" 
            Seal2:this.formContainerData.Seal2, 
            Seal3:this.formContainerData.Seal3, 
            Seal4:this.formContainerData.Seal4,
            Consignee:this.formContainerData.Consignee,// Consignatario de la carga 
            ShipperOwn:this.formContainerData.ShipperOwn,//Dueño para contenedores personalizados 
            Shipper:this.formContainerData.Shipper,// vendedor de la mercancia 
            BlNo:this.formContainerData.BlNo,// identificacion de la carga para nacionalizacion 
            Dua:this.formContainerData.Dua,// referencia aguanal 
        }
        this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;

        this.$http.ejecutar("PUT", "StowagePlanningCargo-update", StowagePlanningCargoJson, { root: 'StowagePlanningCargoJson' })
        .then(response => {
            let res = [...response.data.data];
            this.$notify({
                group: 'container',
                text: res[0].Response,
                type: "success"
            });
            this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
            this.$emit('submited', res[0]);
            this.$store.state.planificacionestiba.modalContainerData = false;
        }).catch((err) => {
            this.$store.commit('planificacionestiba/messageMutation', err);
            this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
        });
    }
    function checkImdgCode(event){
        if(event.target.value !== '' && event.target.value !== null){
            const id = event.target.value;

             const index = this.hazardCodeAll.map(function(e) {
                return e.ImdgClassId; 
            }).indexOf(id);
            if(index !== -1){
                this.formContainerData.ImdgCode = this.hazardCodeAll[index].ImdgClassAbbrev;
                this.$v.formContainerData.ImdgCode.$touch();
                try{
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
                    this.$http.ejecutar('GET', 'Imdg-list', { ImdgClassId:id,filter:'ACTIVO' }, '').then(responseBay => {
                        if(responseBay.status === 200){
                            this.formContainerData.ImdgClassCode = '';
                            this.formContainerData.ImdgId = null;
                            this.hazardClassAll = responseBay.data.data;
                            this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                        }else{
                            this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                        }
                    }).catch(err => {
                        this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                        this.$store.commit('maquina/messageMutation', err);
                    })
                } catch (error) {
                    this.$store.state.planificacionestiba.apiState = ENUM.ERROR;
                    this.$store.commit('planificacionestiba/messageMutation', error);
                }
            }
        }else{
            this.formContainerData.ImdgCode = '';
            this.formContainerData.ImdgClassCode = '';
            this.formContainerData.ImdgId = null;
            this.hazardClassAll = [];
            this.$v.formContainerData.ImdgCode.$touch();
        }
    }
    function checkImdgClass(event){
        if(event.target.value !== '' && event.target.value !== null){
            const id = event.target.value;

             const index = this.hazardClassAll.map(function(e) {
                return e.ImdgId; 
            }).indexOf(id);
            if(index !== -1){
                this.formContainerData.ImdgClassCode = this.hazardClassAll[index].ImdgCod;
                this.$v.formContainerData.ImdgClassCode.$touch();
            }
        }else{
            this.formContainerData.ImdgClassCode = '';
            this.$v.formContainerData.ImdgClassCode.$touch();
        }
    }
    function checkBay(event){
        if(event.target.value !== '' && event.target.value !== null){
            const id = event.target.value;
            
            const index = this.bayAll.map(function(e) {
                return e.VesselBayId; 
            }).indexOf(id);
            if(index !== -1){
                this.formContainerData.BayCode = this.bayAll[index].BayCode;
                this.slotAll = this.bayAll[index].PositionJson;
                this.$v.formContainerData.BayCode.$touch();
            }
        }else{
            this.formContainerData.BayCode = '';
            //this.formContainerData.VesselBayPosId = null;
            this.slotAll = [];
            this.$v.formContainerData.BayCode.$touch();
        }
    }
    function checkPackagingGroupCode(event){
        if(event.target.value !== '' && event.target.value !== null){
            const id = event.target.value;

             const index = this.packagingGroupAll.map(function(e) {
                return e.PackagingGroupId; 
            }).indexOf(id);
            if(index !== -1){
                this.formContainerData.PackagingGroupCode = this.packagingGroupAll[index].PackagingGroupCode;
                this.$v.formContainerData.PackagingGroupCode.$touch();
            }
        }else{
            this.formContainerData.PackagingGroupCode = '';
            this.$v.formContainerData.PackagingGroupCode.$touch();
        }
    }
    function checkSlot(event){
        if(event.target.value !== '' && event.target.value !== null){
            const id = event.target.value;

             const index = this.slotAll.map(function(e) {
                return e.VesselBayPosId; 
            }).indexOf(id);
            if(index !== -1){
                this.formContainerData.BaySlot = this.slotAll[index].CodPosition;
                this.$v.formContainerData.BaySlot.$touch();
            }
        }else{
            this.formContainerData.BaySlot = '';
            this.$v.formContainerData.BaySlot.$touch();
        }
    }
    function checkClass(event){
        if(event.target.value !== '' && event.target.value !== null){
            const id = event.target.value;

             const index = this.classAll.map(function(e) {
                return e.TpCargoClassId; 
            }).indexOf(id);
            if(index !== -1){
                this.formContainerData.Class = this.classAll[index].TpCargoClassCode;
                this.$v.formContainerData.Class.$touch();
            }
        }else{
            this.formContainerData.Class = '';
            this.$v.formContainerData.Class.$touch();
        }
    }
    function checkCargoStatus(event){
        if(event.target.value !== '' && event.target.value !== null){
            const id = event.target.value;

             const index = this.cargoStatusAll.map(function(e) {
                return e.TpCargoStatusId; 
            }).indexOf(id);
            if(index !== -1){
                this.formContainerData.Status = this.cargoStatusAll[index].TpCargoStatusName;
                if(this.formContainerData.Status === "EMPTY"){
                    if(this.obligationRefeerDisabled){
                        this.formContainerData.PluggedIn = false;
                    }
                }
                this.$v.formContainerData.Status.$touch();
            }
        }else{
            this.formContainerData.Status = '';
            this.$v.formContainerData.Status.$touch();
        }
    }
    function checkLoadPort(event){
        if(event.target.value !== '' && event.target.value !== null){
            const id = event.target.value;

             const index = this.portAll.map(function(e) {
                return e.PortId; 
            }).indexOf(id);
            if(index !== -1){
                this.formContainerData.LoadPort = this.portAll[index].PortCode;
                this.$v.formContainerData.LoadPort.$touch();
            }
        }else{
            this.formContainerData.LoadPort = '';
            this.$v.formContainerData.LoadPort.$touch();
        }
    }
    function checkDischargePort(event){
        if(event.target.value !== '' && event.target.value !== null){
            const id = event.target.value;

             const index = this.portAll.map(function(e) {
                return e.PortId; 
            }).indexOf(id);
            if(index !== -1){
                this.formContainerData.DischargePort = this.portAll[index].PortCode;
                this.$v.formContainerData.DischargePort.$touch();
            }
        }else{
            this.formContainerData.DischargePort = '';
            this.$v.formContainerData.DischargePort.$touch();
        }
    }
    function checkDeliveryPort(event){
        if(event.target.value !== '' && event.target.value !== null){
            const id = event.target.value;

             const index = this.deliveryPortAll.map(function(e) {
                return e.PortId; 
            }).indexOf(id);
            if(index !== -1){
                this.formContainerData.DeliveryPort = this.deliveryPortAll[index].PortCode;
                this.$v.formContainerData.DeliveryPort.$touch();
            }
        }else{
            this.formContainerData.DeliveryPort = '';
            this.$v.formContainerData.DeliveryPort.$touch();
        }
    }
    function checkOptionalPort(event){
        if(event.target.value !== '' && event.target.value !== null){
            const id = event.target.value;

             const index = this.deliveryPortAll.map(function(e) {
                return e.PortId; 
            }).indexOf(id);
            if(index !== -1){
                this.formContainerData.OptionalPort = this.deliveryPortAll[index].PortCode;
                this.$v.formContainerData.OptionalPort.$touch();
            }
        }else{
            this.formContainerData.OptionalPort = '';
            this.$v.formContainerData.OptionalPort.$touch();
        }
    }
    function checkOriginalPortLoading(event){
        if(event.target.value !== '' && event.target.value !== null){
            const id = event.target.value;

             const index = this.deliveryPortAll.map(function(e) {
                return e.PortId; 
            }).indexOf(id);
            if(index !== -1){
                this.formContainerData.OriginalPortLoading = this.deliveryPortAll[index].PortCode;
                this.$v.formContainerData.OriginalPortLoading.$touch();
            }
        }else{
            this.formContainerData.OriginalPortLoading = '';
            this.$v.formContainerData.OriginalPortLoading.$touch();
        }
    }
    function checkTranshipmentPort(event){
        if(event.target.value !== '' && event.target.value !== null){
            const id = event.target.value;

             const index = this.deliveryPortAll.map(function(e) {
                return e.PortId; 
            }).indexOf(id);
            if(index !== -1){
                this.formContainerData.TranshipmentPort = this.deliveryPortAll[index].PortCode;
                this.$v.formContainerData.TranshipmentPort.$touch();
            }
        }else{
            this.formContainerData.TranshipmentPort = '';
            this.$v.formContainerData.TranshipmentPort.$touch();
        }
    }
    function checkCarrier(event){
        if(event.target.value !== '' && event.target.value !== null){
            const id = event.target.value;

            const index = this.serviceLineAll.map(function(e) {
                return e.ShippingLineId; 
            }).indexOf(id);
            if(index !== -1){
                this.formContainerData.ShippingLineCode = this.serviceLineAll[index].ShippingLineCode;
                this.formContainerData.ShippingLineName = this.serviceLineAll[index].ShippingLineName;
                this.$v.formContainerData.ShippingLineName.$touch();
                this.$v.formContainerData.ShippingLineCode.$touch();
            }
        }else{
            this.formContainerData.ShippingLineCode = "";
            this.formContainerData.ShippingLineName = "";
            this.$v.formContainerData.ShippingLineCode.$touch();
            this.$v.formContainerData.ShippingLineName.$touch();
        }
    }
    function checkContainerType(event){
        if(event.target.value !== '' && event.target.value !== null){
            const id = event.target.value;

             const index = this.containerAll.map(function(e) {
                return e.MetadataId; 
            }).indexOf(id);
            if(index !== -1){

                this.formContainerData.IsoCode = this.containerAll[index].MetadataDesc;
                this.formContainerData.TpCargoName = this.containerAll[index].TpCargoName;
                this.formContainerData.TpCargoCode = this.containerAll[index].TpCargoCode;
                this.formContainerData.DescriptionType = this.containerAll[index].TpCargoDetailName;
                this.formContainerData.Size = this.containerAll[index].Size;
                this.formContainerData.SizeId = this.containerAll[index].SizeId;
                this.$v.formContainerData.IsoCode.$touch();
                this.$v.formContainerData.Size.$touch();

                if(this.formContainerData.Size){
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
                    this.$http.ejecutar('GET', 'StowagePlanningFreeVesselBayPos-by-Size', { StowagePlanningId:this.planificacionId,EdiFileId:this.EdiFileId, Size:this.formContainerData.Size }, '').then(responseBay => {
                        if(responseBay.status === 200){
                            this.bayAll = responseBay.data.data;
                            this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                        }else{
                            this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                        }
                    }).catch(err => {
                        this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                        this.$store.commit('maquina/messageMutation', err);
                    })
                }
            }
        }
    }
    function headingCheck(event){
        if(event.target.value !== '' && event.target.value !== null){
            try{
                const HeadingId = event.target.value;
                this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
                this.$http.ejecutar('GET', 'Commodity-list', { Filter: 'ACTIVO', HeadingId:HeadingId }, '').then(responseCommodity => {
                    if(responseCommodity.status === 200){
                        this.commodityAll = responseCommodity.data.data;
                        this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                    }else{
                        this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                    }
                }).catch(err => {
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                    this.$store.commit('maquina/messageMutation', err);
                })
            } catch (error) {
                this.$store.state.planificacionestiba.apiState = ENUM.ERROR;
                this.$store.commit('planificacionestiba/messageMutation', error);
            }
        }
    }
    function resetInputs(){
        this.formContainerData.ContainerCode='';
        this.formContainerData.VesselBayId=null;
        this.formContainerData.BayCode=0;
        this.formContainerData.VesselBayPosId=null;
        this.formContainerData.BaySlot=0;
        this.formContainerData.SizeId=null;
        this.formContainerData.Size='';
        this.formContainerData.TpCargoClassId=null;
        this.formContainerData.Class='';
        this.formContainerData.TpCargoStatusId=null;
        this.formContainerData.Status='';
        this.formContainerData.LoadPortId=null;
        this.formContainerData.LoadPort='';
        this.formContainerData.DischargePortId=null;
        this.formContainerData.DischargePort='';
        this.formContainerData.DeliveryPortId=null;
        this.formContainerData.DeliveryPort='';
        this.formContainerData.OptionalPortId=null;
        this.formContainerData.OptionalPort='';
        this.formContainerData.TranshipmentPortId=null;
        this.formContainerData.TranshipmentPort='';
        this.formContainerData.OriginalPortLoading='';
        this.formContainerData.OriginalPortLoadingId=null;
        this.formContainerData.ShippingLineId=null;
        this.formContainerData.ShippingLineCode='';
        this.formContainerData.ShippingLineName='';
        this.formContainerData.HeadingId=null;
        this.formContainerData.CommodityId=null;
        this.formContainerData.CommodityCode='';
        this.formContainerData.CommodityIdNumber='';
        this.formContainerData.HandlingRemark='';
        this.formContainerData.ContainerRemark='';
        this.formContainerData.SpecialInstruction='';
        this.formContainerData.GeneralInfo='';
        this.formContainerData.DescriptionOfGoods='';
        this.formContainerData.BookingNumber='';
        this.formContainerData.Consignee='';
        this.formContainerData.ShipperOwn='';
        this.formContainerData.Shipper='';
        this.formContainerData.BlNo='';
        this.formContainerData.Dua='';
        //columna 2
        this.formContainerData.IsoCode='';
        this.formContainerData.TpCargoId=null;
        this.formContainerData.MetadataId=null;
        this.formContainerData.Lenght=0.00;
        this.formContainerData.UnitMeasureLenght=null;
        this.formContainerData.Height=0.00;
        this.formContainerData.UnitMeasureHeigth=null;
        this.formContainerData.TpCargoName='';
        this.formContainerData.TpCargoCode='';
        this.formContainerData.Height=0.00;
        this.formContainerData.UnitMeasureHeigth=null;
        this.formContainerData.Weigth=0.00;
        this.formContainerData.UnitMeasureWeigth=null;
        this.formContainerData.Vgm=0.00;
        this.formContainerData.UnitMeasureVgm=null;
        this.formContainerData.DescriptionType='';
        //columna 3
        this.formContainerData.UnitMeasureRf=null;
        this.formContainerData.ReeferSetting='';
        this.formContainerData.MinimumRangeRf='';
        this.formContainerData.MaximumRangeRf='';
        this.formContainerData.PluggedIn=true;
        //columna 4
        this.formContainerData.UnitMeasureDim=null;
        this.formContainerData.DimFront=0.00;
        this.formContainerData.DimBack=0.00;
        this.formContainerData.DimLeft=0.00;
        this.formContainerData.DimRight=0.00;
        this.formContainerData.DimTop=0.00;
        //columna 5
        this.formContainerData.ImdgClassId=null;
        this.formContainerData.ImdgId=null;
        this.formContainerData.ImdgCode='';
        this.formContainerData.ImdgClassCode='';
        this.formContainerData.ImgdPageNo='';
        this.formContainerData.UnNumber='';
        this.formContainerData.FlashPoint='';
        this.formContainerData.UnitMeasureFlashPoint=null;
        this.formContainerData.PackagingGroupId=null;
        this.formContainerData.PackagingGroupCode='';
        this.formContainerData.EmergencySheduleNo='';
        this.formContainerData.MedicalGuide='';
        this.formContainerData.ImdgCodePlaceCardUpper='';
        this.formContainerData.ImdgCodePlaceCardLower='';
        this.formContainerData.DangerousLabel1='';
        this.formContainerData.DangerousLabel2='';
        this.formContainerData.DangerousLabel3='';
        this.formContainerData.DangerousLabelOptional='';
        this.formContainerData.DescriptionOfGoodsDGS='';
        this.formContainerData.Seal1='';
        this.formContainerData.Seal2='';
        this.formContainerData.Seal3='';
        this.formContainerData.Seal4='';

        let DimTopInput = this.$refs.DimTopInput.$el.querySelector("input");
        DimTopInput.value = 0.00;

        let DimRightInput = this.$refs.DimRightInput.$el.querySelector("input");
        DimRightInput.value = 0.00;

        let DimLeftInput= this.$refs.DimLeftInput.$el.querySelector("input");
        DimLeftInput.value = 0.00;

        let DimBackInput = this.$refs.DimBackInput.$el.querySelector("input");
        DimBackInput.value = 0.00;

        let DimFrontInput = this.$refs.DimFrontInput.$el.querySelector("input");
        DimFrontInput.value = 0.00;

        let inputWeigth = this.$refs.weigthInput.$el.querySelector("input");
        inputWeigth.value = 0.00;

        let inputVgm = this.$refs.vgmInput.$el.querySelector("input");
        inputVgm.value = 0.00;

        let inputLenght= this.$refs.lenghtInput.$el.querySelector("input");
        inputLenght.value = 0.00;

        let inputHeigth = this.$refs.heightInput.$el.querySelector("input");
        inputHeigth.value = 0.00;


        this.$v.$reset();
    }
    //computed
    function apiStateLoading() {
        return this.apiState === ENUM.LOADING;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING || this.apiStateForm === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }
    function optionsListWeight() {
        if (this.dataWeight.length === 0) {
            return [
                {
                    value: null,
                    label: "*",
                },
            ];
        } else {
            const chart = [];
            this.dataWeight.map(function(e) {
                if (e.FgActUnitMeasure === true) {
                    chart.push({
                        value: e.UnitMeasureId,
                        label: e.UnitMeasureAbbrev,
                    });
                }
            });
            return chart;
        }
    }
    function optionsListLongitud() {
        if (this.dataLongitud.length === 0) {
            return [
                {
                    value: null,
                    label: "*",
                },
            ];
        } else {
            const chart = [];
            this.dataLongitud.map(function(e) {
                if (e.FgActUnitMeasure === true) {
                    chart.push({
                        value: e.UnitMeasureId,
                        label: e.UnitMeasureAbbrev,
                    });
                }
            });
            return chart;
        }
    }
    function optionList(){
        return [
            {
                value:null,
                label: this.$t('label.selectIt'),
            },
        ];
    }
    function optionsListCarrier() {
        if (this.serviceLineAll.length === 0) {
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.serviceLineAll.map(function(e) {
                chart.push({
                    value: e.ShippingLineId,
                    label: e.ShippingLineCode,
                });
            });
            return chart;
        }
    }
    function optionsListClass() {
        if (this.classAll.length === 0) {
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.classAll.map(function(e) {
                if (e.FgActTpCargoClass === true) {
                    chart.push({
                        value: e.TpCargoClassId,
                        label: e.TpCargoClassCode,
                    });
                }
            });
            return chart;
        }
    }
    function optionsListCargoStatus() {
        if (this.cargoStatusAll.length === 0) {
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.cargoStatusAll.map(function(e) {
                if (e.FgActTpCargoStatus === true) {
                    chart.push({
                        value: e.TpCargoStatusId,
                        label: e.TpCargoStatusName,
                    });
                }
            });
            return chart;
        }
    }
    function optionsListPortAll() {
        if (this.portAll.length === 0) {
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.portAll.map(function(e) {
                chart.push({
                    value: e.PortId,
                    label: e.PortCode,
                });
            });
            return chart;
        }
    }
    function optionsListDeliveryAll() {
        if (this.deliveryPortAll.length === 0) {
            return [
                {
                    value: null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value: null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.deliveryPortAll.map(function(e) {
                if (e.FgActPort === true) {
                    chart.push({
                        value: e.PortId,
                        label: e.PortCode,
                    });
                }
            });
            return chart;
        }
    }
    function optionsListHeadingAll() {
        if (this.headingAll.length === 0) {
            return [
                {
                    value: null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value: null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.headingAll.map(function(e) {
                if (e.FgActHeading === true) {
                    chart.push({
                        value: e.HeadingId,
                        label: e.HeadingName,
                    });
                }
            });
            return chart;
        }
    }
    function optionsListHazardCodeAll() {
        if (this.hazardCodeAll.length === 0) {
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.hazardCodeAll.map(function(e) {
                if (e.FgActImdgClass === true) {
                    chart.push({
                        value: e.ImdgClassId,
                        label: e.ImdgClassAbbrev,
                    });
                }
            });
            return chart;
        }
    }
    function optionsListHazardClassAll(){
        if(this.hazardClassAll.length === 0){
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        }else{
            const chart = [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.hazardClassAll.map(function(e) {
                if (e.FgActImdg === true) {
                    chart.push({
                        value: e.ImdgId,
                        label: e.ImdgCod,
                    });
                }
            });
            return chart;
        }
    }
    function optionsListrefeerAll() {
        if (this.refeerAll.length === 0) {
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.refeerAll.map(function(e) {
                if (e.FgActUnitMeasure === true) {
                    chart.push({
                        value: e.UnitMeasureId,
                        label: e.UnitMeasureName,
                    });
                }
            });
            return chart;
        }
    }
    function optionsListPackagingGroupAll() {
        if (this.packagingGroupAll.length === 0) {
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.packagingGroupAll.map(function(e) {
                if (e.FgActPackGroup === true) {
                    chart.push({
                        value: e.PackagingGroupId,
                        label: e.PackagingGroupCode,
                    });
                }
            });
            return chart;
        }
    }
    function optionsListContainerTypeAll() {
        if (this.containerAll.length === 0) {
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.containerAll.map(function(e) {
                chart.push({
                    value: e.MetadataId,
                    label: e.MetadataDesc,
                });
            });
            return chart;
        }
    }
    function optionsListCommodityAll() {
        if (this.commodityAll.length === 0) {
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.commodityAll.map(function(e) {
                if (e.FgActCommodity === true) {
                    chart.push({
                        value: e.CommodityId,
                        label: e.CommodityName,
                    });
                }
            });
            return chart;
        }
    }
    function optionListBayAll(){
        if (this.bayAll.length === 0) {
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.bayAll.map(function(e) {
                chart.push({
                    value: e.VesselBayId,
                    label: e.BayCode,
                });
            });
            return chart;
        }
    }
    function optionListSlotAll(){
        if (this.slotAll.length === 0) {
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.slotAll.map(function(e) {
                chart.push({
                    value: e.VesselBayPosId,
                    label: e.CodPosition,
                });
            });
            return chart;
        }
    }
    function limitWEIGHT(){
        let value = 0.00;
        const code = this.formContainerData.IsoCode;
        const index = this.containerAll.map(function(e) {
            return e.MetadataDesc; 
        }).indexOf(code);
        if(index !== -1){ 
            switch (this.formContainerData.Status) {
                case 'EMPTY':
                    const WeigthEmptyJson = this.containerAll[index].WeigthEmptyJson;
                    let indexWeigthEmpty = WeigthEmptyJson.map(function(e) {
                        return e.UnitMeasureId; 
                    }).indexOf(this.formContainerData.UnitMeasureWeigth);
                    if(indexWeigthEmpty !== -1){
                        value = parseFloat(WeigthEmptyJson[indexWeigthEmpty].Value).toFixed(2);
                    }else{
                        value = 0.00;
                    }
                    break;
                case 'FULL':
                    const MaxGrossWeigthJson = this.containerAll[index].MaxGrossWeigthJson;
                    let indexWeigthGross = MaxGrossWeigthJson.map(function(e) {
                        return e.UnitMeasureId; 
                    }).indexOf(this.formContainerData.UnitMeasureWeigth);
                    if(indexWeigthGross !== -1){
                        value = parseFloat(MaxGrossWeigthJson[indexWeigthGross].Value).toFixed(2);
                    }else{
                        value = 0.00;
                    }
                    break;
                default:
                    value = 0.00;
                    break;
            }
        }else{
            value = 0.00;
        }
        return value;
    }
    function limitVGM(){
        let value = 0.00;
        if(this.obligationVGM){
            const code = this.formContainerData.IsoCode;
            const index = this.containerAll.map(function(e) {
                return e.MetadataDesc; 
            }).indexOf(code);
            if(index !== -1){
                const MaxLoadJson = this.containerAll[index].MaxLoadJson;
                let indexLoad = MaxLoadJson.map(function(e) {
                    return e.UnitMeasureId; 
                }).indexOf(this.formContainerData.UnitMeasureVgm);
                if(indexLoad !== -1){
                    value = parseFloat(MaxLoadJson[indexLoad].Value).toFixed(2);
                }else{
                    value = 0.00;
                }
                return value;
            }else{
                return value;
            }
        }else{
            return null;
        }
    }
    function numberTotalWEIGHT(){
        let modifiedValue = parseFloat(this.limitWEIGHT.replace(/,/g, '.')).toFixed(2);
        let value = (new Intl.NumberFormat("de-DE").format(modifiedValue)).toString();
        this.dataWeight.map(async(data) => {
            if(this.formContainerData.UnitMeasureWeigth == data.UnitMeasureId){
                value = value + ' ' + data.UnitMeasureAbbrev;
            }
        });
        return value;
    }
    function numberTotalVGM(){
        let modifiedValue = parseFloat(this.limitVGM.replace(/,/g, '.')).toFixed(2);
        let value = (new Intl.NumberFormat("de-DE").format(modifiedValue)).toString();
        this.dataWeight.map(async(data) => {
            if(this.formContainerData.UnitMeasureVgm == data.UnitMeasureId){
                value = value + ' ' + data.UnitMeasureAbbrev;
            }
        });
        return value;
    }
    function obligationVGM(){
        if(this.modalContainerData){
            return this.EdiFileInfo.TpEdiTransacName === "CARGA" && this.formContainerData.Status === "FULL";  
        }else{
            return false;
        }
    }
    function obligationResinto(){
        if(this.modalContainerData){
            let bandera = false;
            const code = this.formContainerData.IsoCode;
            const index = this.containerAll.map(function(e) {
                return e.MetadataDesc; 
            }).indexOf(code);
            if(index !== -1){
                bandera = this.containerAll[index].FgApplySeal;
            }else{
                bandera = this.myData.FgApplySeal;
            }
            return bandera && this.formContainerData.Status === "FULL";
        }else{
            return false;
        }
    }
    function oversizeInfoDisabled(){
        if(this.modalContainerData){
            let bandera = false;
            const code = this.formContainerData.IsoCode;
            const index = this.containerAll.map(function(e) {
                return e.MetadataDesc; 
            }).indexOf(code);
            if(index !== -1){
                bandera = !this.containerAll[index].FgApplyOversize;
            }else{
                bandera = !this.myData.FgApplyOversize;
            }
            return bandera;
        }else{
            return false;
        }    
    }
    function obligationRefeerDisabled(){
        let bandera = false;
        const code = this.formContainerData.IsoCode;
        const index = this.containerAll.map(function(e) {
            return e.MetadataDesc; 
        }).indexOf(code);
        if(index !== -1){
            bandera = this.containerAll[index].FgApplyRefrigeration;
        }else{
            bandera = this.myData.FgApplyRefrigeration;
        }
        return bandera;
    }
    function obligationUnidadFlashPoint(){
        return this.formContainerData.FlashPoint !== '';
    }
    function obligationImdgCode(){
        return this.formContainerData.ImdgCode !== '';
    }
    function obligationBaySlot(){
        if(this.$store.state.planificacionestiba.modalContainerData){
            return this.EdiFileInfo.TpEdiId == "AE4FB2C8-8471-43C8-9DE0-6394173F0330";
        }
        return true;
    }
    //watch
    async function modalContainerData(newQuestion,Oldquestion){
        if(newQuestion === false){
            this.resetInputs();
            document.getElementsByTagName("html")[0].style.overflow = "auto";
        }else{
            try {
                const Index = this.myDataItinirary.map(function(e) {
                    return e.ItineraryId; 
                }).indexOf(this.ItineraryId);
                if(Index !== -1 && this.dataWeight.lenght !== 0 && this.dataLongitud.length !== 0){
                    document.getElementsByTagName("html")[0].style.overflow = "hidden";

                    this.formContainerData.UnitMeasureDim= this.dataLongitud[0].UnitMeasureId;

                    const ServiceId = this.myDataItinirary[Index].ServiceId;
                    
                    this.formContainerData.BayCode = this.myData.bay;
                    this.formContainerData.BaySlot = this.myData.slot;
                    this.formContainerData.ContainerCode = this.myData.siglas;
                    this.formContainerData.SizeId = this.myData.sizeid;
                    this.formContainerData.Size = this.myData.sizeModal.replaceAll("\\D+","");
                    this.formContainerData.Class = this.myData.class;
                    this.formContainerData.TpCargoStatusId = this.myData.statusid;
                    this.formContainerData.Status = this.myData.status;
                    this.formContainerData.LoadPortId = this.myData.polid;
                    this.formContainerData.LoadPort = this.myData.pol;
                    this.formContainerData.DischargePortId = this.myData.podid;
                    this.formContainerData.DischargePort = this.myData.pod;
                    this.formContainerData.DeliveryPort = this.myData.delivery;
                    this.formContainerData.OptionalPortId = this.myData.optportid;
                    this.formContainerData.OptionalPort = this.myData.optport;
                    this.formContainerData.TranshipmentPortId = this.myData.transhipment_portid;
                    this.formContainerData.TranshipmentPort = this.myData.transhipment_port;
                    this.formContainerData.ShippingLineCode = this.myData.shippinglinecode;
                    this.formContainerData.ShippingLineName = this.myData.carrier;
                    this.formContainerData.HeadingId = this.myData.headingid;
                    this.formContainerData.CommodityId = this.myData.commodityid;
                    this.formContainerData.CommodityCode = this.myData.commodity_code;
                    this.formContainerData.CommodityIdNumber = this.myData.commodity_id_number;
                    this.formContainerData.HandlingRemark = this.myData.handlink_remark;
                    this.formContainerData.ContainerRemark = this.myData.container_remark;
                    this.formContainerData.SpecialInstruction = this.myData.special_instruction;
                    this.formContainerData.GeneralInfo = this.myData.general_info;
                    this.formContainerData.BookingNumber = this.myData.booking_number;
                    this.formContainerData.OriginalPortLoadingId = this.myData.loading_portid;
                    this.formContainerData.OriginalPortLoading = this.myData.loading_port;
                    this.formContainerData.DescriptionOfGoods = this.myData.descriptionofgoods;
                    this.formContainerData.Consignee = this.myData.consignee;
                    this.formContainerData.ShipperOwn = this.myData.shipperown;
                    this.formContainerData.Shipper = this.myData.shipper;
                    this.formContainerData.Dua = this.myData.dua;
                    this.formContainerData.BlNo = this.myData.blno;
                    

                    this.formContainerData.IsoCode = this.myData.container_type;
                    this.formContainerData.UnitMeasureWeigth = this.myData.weigthid ? this.myData.weigthid : this.dataWeight[0].UnitMeasureId;
                    this.formContainerData.UnitMeasureVgm = this.myData.vgmid ? this.myData.vgmid : this.dataWeight[0].UnitMeasureId;

                    this.formContainerData.UnitMeasureLenght = this.myData.lengthid ? this.myData.lengthid : this.dataLongitud[0].UnitMeasureId;
                    this.formContainerData.UnitMeasureHeigth = this.myData.heightid ? this.myData.heightid : this.dataLongitud[0].UnitMeasureId;
                    
                    const weigthValue = this.myData.weigth ? parseFloat(this.myData.weigth).toFixed(2) : 0.00;
                    const vgmValue = this.myData.vgm ? parseFloat(this.myData.vgm).toFixed(2) : 0.00;
                    const lenghtValue = this.myData.length ? parseFloat(this.myData.length).toFixed(2) : 0.00;
                    const heigthValue = this.myData.height ? parseFloat(this.myData.height).toFixed(2) : 0.00;

                    this.formContainerData.Weigth = weigthValue;
                    this.formContainerData.Vgm = vgmValue;
                    this.formContainerData.Lenght = lenghtValue;
                    this.formContainerData.Height = heigthValue;

                    let inputWeigth = this.$refs.weigthInput.$el.querySelector("input");
                    inputWeigth.value = weigthValue;

                    let inputVgm = this.$refs.vgmInput.$el.querySelector("input");
                    inputVgm.value = vgmValue;

                    let inputLenght= this.$refs.lenghtInput.$el.querySelector("input");
                    inputLenght.value = lenghtValue;

                    let inputHeigth = this.$refs.heightInput.$el.querySelector("input");
                    inputHeigth.value = heigthValue;

                    this.formContainerData.TpCargoName=this.myData.group_type;
                    this.formContainerData.TpCargoCode=this.myData.tp_cargo_code;
                    this.formContainerData.DescriptionType=this.myData.description;
                    
                    this.formContainerData.PluggedIn = this.myData.plugged_in;

                    this.formContainerData.ReeferSetting=this.myData.reefer_setting;
                    this.formContainerData.MinimumRangeRf=this.myData.minimun_range_rf;
                    this.formContainerData.MaximumRangeRf=this.myData.maximun_range_rf;

                    const DimTopValue = this.myData.off_standard_dim_top ? parseFloat(this.myData.off_standard_dim_top).toFixed(2) : 0.00;
                    const DimRightValue = this.myData.off_standard_dim_right ? parseFloat(this.myData.off_standard_dim_right).toFixed(2) : 0.00;
                    const DimLeftValue = this.myData.off_standard_dim_left ? parseFloat(this.myData.off_standard_dim_left).toFixed(2) : 0.00;
                    const DimBackValue = this.myData.off_standard_dim_back ? parseFloat(this.myData.off_standard_dim_back).toFixed(2) : 0.00;
                    const DimFrontValue = this.myData.off_standard_dim_front ? parseFloat(this.myData.off_standard_dim_front).toFixed(2) : 0.00;

                    this.formContainerData.ImdgClassCode=this.myData.imdg_code;
                    this.formContainerData.ImdgCode=this.myData.imdg_class_code;
                    this.formContainerData.ImgdPageNo=this.myData.imdg_page_no;
                    this.formContainerData.UnNumber=this.myData.un_number;
                    this.formContainerData.FlashPoint=this.myData.flash_point;
                    this.formContainerData.PackagingGroupId=this.myData.packaging_group_id;
                    this.formContainerData.PackagingGroupCode=this.myData.packaging_group;
                    this.formContainerData.EmergencySheduleNo=this.myData.emergency_schedule_no;
                    this.formContainerData.MedicalGuide=this.myData.medical_firts_aid_guide_no;
                    this.formContainerData.ImdgCodePlaceCardUpper=this.myData.orange_hazard_place_card_lower_part_identifier;
                    this.formContainerData.ImdgCodePlaceCardLower=this.myData.orange_hazard_place_card_upper_part_identifier;
                    this.formContainerData.DangerousLabel1=this.myData.dangerous_good_label_1;
                    this.formContainerData.DangerousLabel2=this.myData.dangerous_good_label_2;
                    this.formContainerData.DangerousLabel3=this.myData.dangerous_good_label_3;
                    this.formContainerData.DangerousLabelOptional = this.myData.dangerous_label_optional;
                    this.formContainerData.DescriptionOfGoodsDGS=this.myData.dangerous_good_additional_information;
                    this.formContainerData.UnitMeasureFlashPoint = this.myData.UnitMeasureFlashPointId;
                    this.formContainerData.Seal1=this.myData.seal1;
                    this.formContainerData.Seal2=this.myData.seal2;
                    this.formContainerData.Seal3=this.myData.seal3;
                    this.formContainerData.Seal4=this.myData.seal4;

                    //optional
                    this.formContainerData.VesselBayId = this.myData.VesselBayId;
                    
                    this.formContainerData.TpCargoClassId = this.myData.TpCargoClassId;
                    this.formContainerData.ShippingLineId = this.myData.ShippingLineId;
                    this.formContainerData.TpCargoId = this.myData.TpCargoId;
                    this.formContainerData.MetadataId = this.myData.MetadataId;
                    this.formContainerData.ImdgClassId = this.myData.ImdgClassId;
                    this.formContainerData.ImdgId = this.myData.ImdgId;

                    this.AlertJson=this.myData.messagesContent.Alert;
                    this.ErrorJson=this.myData.messagesContent.Error;

                    this.formContainerData.UnitMeasureRf = this.myData.UnitMeasureRfId;

                    this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
                    this.$http.ejecutar('GET', 'StowagePlanningContainerData-by-CargoId', { StowagePlanningCargoId:this.myData.StowagePlanningCargoId }, '').then(responsecontainerDataAll => {
                        if(responsecontainerDataAll.status === 200){
                            let informationsResponse = responsecontainerDataAll.data.data;
                            if(informationsResponse.length !== 0){
                                this.$http.ejecutar('GET', 'TpCargoDetail-by-codiso', { CodIso:'' }, '').then(responsecontainerAll => {
                                    if(responsecontainerAll.status === 200){
                                        this.$http.ejecutar('GET', 'Port-list', { Filter: 'ACTIVO',CountryId: '' }, '').then(responsePortDeliveryAll => {
                                            if(responsePortDeliveryAll.status === 200){
                                                this.$http.ejecutar('GET', 'UnitMeasureByTpUnitMeasureId', { Filter: 'ACTIVO',TpUnitMeasureId:'B6F6DCFD-7246-410C-A576-36462CCF5819' }, '').then(responserefeerAll => {
                                                    if(responserefeerAll.status === 200){
                                                        this.containerAll = responsecontainerAll.data.data;
                                                        this.headingAll = informationsResponse[0].HeadingJson;
                                                        this.hazardCodeAll = informationsResponse[0].ImdgClassJson;
                                                        this.packagingGroupAll = informationsResponse[0].PackagingGroupJson;
                                                        this.serviceLineAll = informationsResponse[0].ServiceLineMasterJson;
                                                        this.deliveryPortAll = responsePortDeliveryAll.data.data;
                                                        this.portAll = informationsResponse[0].ServicePortMasterJson;
                                                        this.classAll = informationsResponse[0].TpCargoClassJson;
                                                        this.cargoStatusAll = informationsResponse[0].TpCargoStatusJson;
                                                        this.refeerAll = responserefeerAll.data.data;
                                                        
                                                        this.formContainerData.DimFront=DimFrontValue;
                                                        this.formContainerData.DimBack=DimBackValue;
                                                        this.formContainerData.DimLeft=DimLeftValue;
                                                        this.formContainerData.DimRight=DimRightValue;
                                                        this.formContainerData.DimTop=DimTopValue;

                                                        let DimTopInput = this.$refs.DimTopInput.$el.querySelector("input");
                                                        DimTopInput.value = DimTopValue;

                                                        let DimRightInput = this.$refs.DimRightInput.$el.querySelector("input");
                                                        DimRightInput.value = DimRightValue;

                                                        let DimLeftInput= this.$refs.DimLeftInput.$el.querySelector("input");
                                                        DimLeftInput.value = DimLeftValue;

                                                        let DimBackInput = this.$refs.DimBackInput.$el.querySelector("input");
                                                        DimBackInput.value = DimBackValue;

                                                        let DimFrontInput = this.$refs.DimFrontInput.$el.querySelector("input");
                                                        DimFrontInput.value = DimFrontValue;

                                                        if(this.obligationRefeerDisabled){
                                                            if (this.refeerAll.length !== 0) {
                                                                if(this.formContainerData.UnitMeasureRf === null){
                                                                    this.formContainerData.UnitMeasureRf = this.refeerAll[0].UnitMeasureId;
                                                                }
                                                            }
                                                        }
                                                        if(this.formContainerData.ImdgClassId !== null && this.formContainerData.ImdgClassId !== '' ||
                                                            this.formContainerData.HeadingId !== null && this.formContainerData.HeadingId !== '' ||
                                                            this.formContainerData.Size !== null && this.formContainerData.Size !== '' && this.formContainerData.Size !== 0
                                                            ){
                                                            this.cargarOptional();
                                                        }else{
                                                            this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                                                        }
                                                    }else{
                                                        this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                                                    }
                                                }).catch(err => {
                                                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                                                    this.$store.commit('maquina/messageMutation', err);
                                                });
                                            }else{
                                                this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                                            }
                                        }).catch(err => {
                                            this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                                            this.$store.commit('maquina/messageMutation', err);
                                        });
                                    }else{
                                        this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                                    }
                                }).catch(err => {
                                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                                    this.$store.commit('maquina/messageMutation', err);
                                });
                            }else{
                                this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADED;
                            }
                        }else{  
                            this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                        }
                    }).catch(err => {
                        this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                        this.$store.commit('maquina/messageMutation', err);
                    });
                }
            } catch (error) {
                this.$store.state.planificacionestiba.apiState = ENUM.ERROR;
                this.$store.commit('planificacionestiba/messageMutation', error);
            }
        }
    }

    export default {
        name:'modal-container-list',
        data,
        props: {
            myData:{
                type:Object,
                required:true,
                default: () => {}
            },
            dataWeight:{
                type: Array,
                required: true,
                default: () => [],
            },
            dataLongitud:{
                type: Array,
                required: true,
                default: () => [],
            }
        },
        validations(){ return FormContainerData(this.limitWEIGHT, this.limitVGM, this.obligationVGM, 
            this.obligationResinto, this.formContainerData.PluggedIn,this.obligationUnidadFlashPoint,this.obligationImdgCode,this.obligationBaySlot) },
        directives: {
            uppercase: {
                bind(el, _, vnode) {
                el.addEventListener('input', (e) => {
                    e.target.value = e.target?.value?.toUpperCase()
                    vnode.componentInstance.$emit('input', e.target?.value?.toUpperCase())
                });
                }
            },
            money: VMoney
        },
        components:{
            Money
        },
        methods:{
            maxLength,
            checkOriginalPortLoading,
            resetInputs,
            checkClass,
            checkCargoStatus,
            checkLoadPort,
            checkDischargePort,
            checkDeliveryPort,
            checkOptionalPort,
            checkTranshipmentPort,
            checkCarrier,
            checkContainerType,
            checkPackagingGroupCode,
            checkBay,
            checkImdgCode,
            checkImdgClass,
            checkSlot,
            headingCheck,
            registerData,
            cerrarModal,
            cargarOptional,
            conversiones,
            alertasInputs,
            alertContent,
            alertType,
            alertMessage,
            onBtnExport
        },
        computed: {
            optionList,
            optionsListCarrier,
            optionsListHeadingAll,
            optionsListClass,
            optionsListWeight,
            optionsListLongitud,
            optionsListCargoStatus,
            optionsListPortAll,
            optionsListrefeerAll,
            optionsListPackagingGroupAll,
            optionsListHazardCodeAll,
            optionsListHazardClassAll,
            optionsListDeliveryAll,
            optionsListContainerTypeAll,
            optionsListCommodityAll,
            optionListBayAll,
            optionListSlotAll,
            limitWEIGHT,
            limitVGM,
            obligationVGM,
            obligationResinto,
            oversizeInfoDisabled,
            numberTotalWEIGHT,
            numberTotalVGM,
            obligationRefeerDisabled,
            obligationUnidadFlashPoint,
            obligationImdgCode,
            obligationBaySlot,
            selectedWeigth(){
                return this.formContainerData.UnitMeasureWeigth;
            },
            selectedVgm(){
                return this.formContainerData.UnitMeasureVgm;
            },
            selectedLenght(){
                return this.formContainerData.UnitMeasureLenght;
            },
            selectedHeight(){
                return this.formContainerData.UnitMeasureHeigth;
            },
            optionRefeer(){
                return this.formContainerData.PluggedIn;
            },
            apiStateLoading,
            apiStateFormLoading,
            ...mapState({
                user: state => state.auth.user,
                modalContainerData: (state) => state.planificacionestiba.modalContainerData,
                apiState: (state) => state.planificacionestiba.apiState,
                apiStateForm: (state) => state.planificacionestiba.apiStateForm,
                EdiFileId: (state) => state.planificacionestiba.EdiFileId,
                EdiFileInfo: (state) => state.planificacionestiba.EdiFileInfo,
                planificacionId: state => state.planificacionestiba.planificacionId,
                myDataItinirary: state => state.planificacionestiba.myDataItinirary,
                ItineraryId: state => state.planificacionestiba.ItineraryId,
            }),
        },
        watch: {
            modalContainerData,
            obligationRefeerDisabled(newValue){
                if(!newValue){
                   this.formContainerData.UnitMeasureRf = null;
                    this.formContainerData.ReeferSetting='';
                    this.formContainerData.MinimumRangeRf='';
                    this.formContainerData.MaximumRangeRf='';
                    this.formContainerData.PluggedIn=false;
                }else{
                    if (this.refeerAll.length !== 0) {
                        if(this.formContainerData.UnitMeasureRf === null){
                            this.formContainerData.UnitMeasureRf = this.refeerAll[0].UnitMeasureId;
                        }
                    }
                    if(this.formContainerData.Status === "EMPTY"){
                        this.formContainerData.PluggedIn=false;
                    }else{
                        this.formContainerData.PluggedIn=true;
                    }
                }
            },
            selectedWeigth(newValue,OldValue){
                if(OldValue !== '' && OldValue !== null && newValue !== '' && newValue !== null){
                    this.conversiones(newValue,OldValue,this.formContainerData.Weigth,1);
                }
            },
            selectedVgm(newValue,OldValue){
                if(OldValue !== '' && OldValue !== null && newValue !== '' && newValue !== null){
                    this.conversiones(newValue,OldValue,this.formContainerData.Vgm,2);
                }
            },
            selectedLenght(newValue,OldValue){
                if(OldValue !== '' && OldValue !== null && newValue !== '' && newValue !== null){
                    this.conversiones(newValue,OldValue,this.formContainerData.Lenght,3);
                }
            },
            selectedHeight(newValue,OldValue){
                if(OldValue !== '' && OldValue !== null && newValue !== '' && newValue !== null){
                    this.conversiones(newValue,OldValue,this.formContainerData.Height,4);
                }
            },
            optionRefeer(newValue){
                if(!newValue){
                    this.formContainerData.ReeferSetting='';
                }
            },
            oversizeInfoDisabled(newValue){
                if(!newValue){
                    this.formContainerData.UnitMeasureDim= this.dataLongitud.lenght !== 0 ? this.dataLongitud[0].UnitMeasureId : null;

                    this.formContainerData.DimFront=0.00;
                    this.formContainerData.DimBack=0.00;
                    this.formContainerData.DimLeft=0.00;
                    this.formContainerData.DimRight=0.00;
                    this.formContainerData.DimTop=0.00;

                    let DimTopInput = this.$refs.DimTopInput.$el.querySelector("input");
                    DimTopInput.value = 0.00;

                    let DimRightInput = this.$refs.DimRightInput.$el.querySelector("input");
                    DimRightInput.value = 0.00;

                    let DimLeftInput= this.$refs.DimLeftInput.$el.querySelector("input");
                    DimLeftInput.value = 0.00;

                    let DimBackInput = this.$refs.DimBackInput.$el.querySelector("input");
                    DimBackInput.value = 0.00;

                    let DimFrontInput = this.$refs.DimFrontInput.$el.querySelector("input");
                    DimFrontInput.value = 0.00;
                }
            }
        }
    }
</script>
<style lang="scss">
    .title-card{
        padding:15px;
    }
    .info-title{
        border: 0px;
        border-bottom: 4px solid;
        border-image: linear-gradient(243deg, rgba(224,236,222,1) 15%, rgba(206,222,251,1) 81%);
        border-image-slice: 1;
        margin: auto;
        text-decoration: none;
    }
    .iso-title{
        border: 0px;
        border-bottom: 4px solid;
        border-image: linear-gradient(90deg, rgba(185,212,219,1) 4%, rgba(65,158,227,1) 50%, rgba(185,212,219,1) 98%);
        border-image-slice: 1;
        margin: auto;
        text-decoration: none;
    }
    .reefer-not-title{
        border: 0px;
        border-bottom: 4px solid;
        border-image: linear-gradient(90deg, rgba(255, 189, 113, 1) 4%, rgba(254, 122, 21, 1) 50%, rgba(255, 189, 113, 1) 98%);
        border-image-slice: 1;
        margin: auto;
        text-decoration: none;
    }
    .reefer-title{
        border: 0px;
        border-bottom: 4px solid;
        border-image: linear-gradient(90deg, rgba(132,222,224,1) 4%, rgba(72,179,223,1) 50%, rgba(132,222,224,1) 98%);
        border-image-slice: 1;
        margin: auto;
        text-decoration: none;
    }
    .oversize-title{
        border: 0px;
        border-bottom: 4px solid;
        border-image: linear-gradient(90deg, rgba(255,221,148,1) 4%, rgba(223,86,72,1) 50%, rgba(255,221,148,1) 98%);
        border-image-slice: 1;
        margin: auto;
        text-decoration: none;
    }
    .dangerous-title{
        border: 0px;
        border-bottom: 4px solid;
        border-image: linear-gradient(243deg, rgba(201,46,32,1) 15%, rgb(247, 154, 142) 81%);
        border-image-slice: 1;
        margin: auto;
        text-decoration: none;
    }
    .seal-title{
        border: 0px;
        border-bottom: 4px solid;
        border-image: linear-gradient(243deg, #009da4 15%, #47CACC 81%);
        border-image-slice: 1;
        margin: auto;
        text-decoration: none;
    }
    .content-general{
        padding: 5px;
        border-top: 0px;
        background: #E0ECDE;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
        margin-bottom:0px;
    }
    .content-iso{
        padding: 5px;
        background: #B9D4DB;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    }
    .content-not-reefer{
        padding: 5px;
        background: #FFBD71;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    }
    .content-reefer{
        padding: 5px;
        background: #86e3ce;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    }
    .content-oversize{
        padding: 5px;
        background: #ffdd94;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    }
    .content-dangerous{
        padding: 5px;
        background:#f8ada3;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    }
    .content-seal{
        padding: 5px;
        background: #47CACC ;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    }
    .not-border{
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        padding: 0px;
    }
    .modal-sm {
        min-width: 85%;
    }
    .backgroundModal{
        .modal-body{
            background:#E4DFD9;
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
        }
        .modal-footer{
            background:#E4DFD9;
        }
    }
    .card-body{
        padding:10px;
        padding-bottom:0px;
    }
    .especial-form .form-row {
        margin-bottom:0px;
    }
    .was-validated .form-control:invalid, .form-control.is-warning {
        border-color: #8a6d3b;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%238a6d3b' viewBox='0 0 16 16'%3e%3cpath d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z' /%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        background-position: right calc(0.375em + 0.1875rem) center;
    }
    .was-validated .form-control:invalid, .form-control.is-invalid {
        border-color: #e55353;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%23e55353' viewBox='0 0 16 16'%3e%3cpath d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z' /%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
    .toatsAlert{
        cursor:pointer;
        width:100%;
        padding:0px !important;
        .close {
            padding: 8px !important;
        }
        
        .icon-toast{
            -webkit-animation: blink-1 2s infinite both;
	        animation: blink-1 2s infinite both;
        }
    }
    .alert-danger{
        color: #ff0303 !important;
        //background-color: rgba(220, 17, 1, 0.16) !important;
        transition:0.5s;
        border: 1px solid rgba(241, 6, 6, 0.81);
        box-shadow: 0px 0px 2px #ff0303;
    }
    .alert-warning{
        color: #ffb103 !important;
        //background-color: rgba(220, 128, 1, 0.16) !important;
        transition:0.5s;
        border: 1px solid rgba(241, 142, 6, 0.81);
        box-shadow: 0px 0px 2px #ff0303;
    }
    .alert-danger:hover{
        background-color: rgba(220, 17, 1, 0.33) !important;
        transition:0.5s;
    }
    .alert-warning:hover{
        background-color: rgba(220, 128, 1, 0.33) !important;
        transition:0.5s;
    }
    /**
    * ----------------------------------------
    * animation blink-1
    * ----------------------------------------
    */
    @-webkit-keyframes blink-1 {
        0%,
        50%,
        100% {
            opacity: 1;
        }
        25%,
        75% {
            opacity: 0;
        }
    }
    @keyframes blink-1 {
        0%,
        50%,
        100% {
            opacity: 1;
        }
        25%,
        75% {
            opacity: 0;
        }
    }
</style>